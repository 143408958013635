import React from 'react';
import { Accordion } from 'react-bootstrap';

const InfoRiskAccordion = ({ data }) => {
    return (
        <>
            <div className="inforisk">
                <div className="innerbg inforisk_innerbg">
                    <Accordion defaultActiveKey={false}>
                        {data.map((dt, i) => (
                            <Accordion.Item eventKey={i}>
                                <Accordion.Header>
                                    {dt.acchead}
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p className="content">
                                        {dt.acccontent}
                                    </p>
                                </Accordion.Body>
                            </Accordion.Item>
                        ))}
                    </Accordion>
                </div>
            </div>
        </>
    )
}

export default InfoRiskAccordion;