
export const Images = {
    discord: require("../Assets/images/discord.png"),
    twitter: require("../Assets/images/twitter.png"),
    // weighscaleone: require("../Assets/images/weighscaleone.png"),
    // weighscaletwo: require("../Assets/images/weighscaletwo.png"),
    borrowone: require("../Assets/images/borrowone.png"),
    borrowonepair: require("../Assets/images/borrowonepair.png"),
    borrowtwo: require("../Assets/images/borrowtwo.png"),
    borrowtwopair: require("../Assets/images/borrowtwopair.png"),
    borrowthree: require("../Assets/images/borrowthree.png"),
    borrowfour: require("../Assets/images/borrowfour.png"),
    borrowfourpair: require("../Assets/images/borrowfourpair.png"),
    search: require("../Assets/images/search.png"),
    mainmarket: require("../Assets/images/mainmarket.png"),
    theadarrow: require("../Assets/images/theadarrow.png"),
    usdc: require("../Assets/images/usdc.png"),
    jlp: require("../Assets/images/jlp.png"),
    sol: require("../Assets/images/sol.png"),
    pyusd: require("../Assets/images/pyusd.png"),
    wbtc: require("../Assets/images/wbtc.png"),
    jitosol: require("../Assets/images/jitosol.png"),
    tbtc: require("../Assets/images/tbtc.png"),
    liquidityone: require("../Assets/images/liquidityone.png"),
    liquiditytwo: require("../Assets/images/liquiditytwo.png"),
    liquiditythree: require("../Assets/images/liquiditythree.png"),
    liquidityfour: require("../Assets/images/liquidityfour.png"),
    coinone: require("../Assets/images/coinone.png"),
    cointwo: require("../Assets/images/cointwo.png"),
    cointhree: require("../Assets/images/cointhree.png"),
    light: require("../Assets/images/light.png"),
    boxtoplogoone: require("../Assets/images/boxtoplogoone.png"),
    boxtoplogotwo: require("../Assets/images/boxtoplogotwo.png"),
    twocoins: require("../Assets/images/twocoins.png"),
    boxtoplogothree: require("../Assets/images/boxtoplogothree.png"),
    hsol: require("../Assets/images/hsol.png"),
    msol: require("../Assets/images/msol.png"),
    liquiditybanner: require("../Assets/images/liquiditybanner.png"),
    multiplybanner: require("../Assets/images/multiplybanner.png"),
    multiplyone: require("../Assets/images/multiplyone.png"),
    multiplytwo: require("../Assets/images/multiplytwo.png"),
    multiplythree: require("../Assets/images/multiplythree.png"),
    multiplyfour: require("../Assets/images/multiplyfour.png"),
    longshortbanner: require("../Assets/images/longshortbanner.png"), 
    longshortone: require("../Assets/images/longshortone.png"),
    longshorttwo: require("../Assets/images/longshorttwo.png"),
    longshortthree: require("../Assets/images/longshortthree.png"),
    longshortfour: require("../Assets/images/longshortfour.png"),
    lendingone: require("../Assets/images/lendingone.png"),
    lendingtwo: require("../Assets/images/lendingtwo.png"),
    lendingthree: require("../Assets/images/lendingthree.png"),
    lendingfour: require("../Assets/images/lendingfour.png"),
    browserwallet: require("../Assets/images/browserwallet.png"),
    coinbase: require("../Assets/images/coinbase.png"),
    walletconnect: require("../Assets/images/walletconnect.png"),
    torus: require("../Assets/images/torus.png"),
    buyins: require("../Assets/images/buyins.png"),
    export: require("../Assets/images/export.png"),
    copy: require("../Assets/images/copy.png"),
    dashboardnodata: require("../Assets/images/dashboard-nodata.png"),
    quiz: require("../Assets/images/quiz.png"),
    alert: require("../Assets/images/alert.png"),
    settings: require("../Assets/images/settings.png"),
    supplyicon: require("../Assets/images/supplyicon.png"),
    borrowicon: require("../Assets/images/borrowicon.png"),
    phantom: require("../Assets/images/phantom.png"),
    solflare: require("../Assets/images/solflare.png"),
    jitoimg: require("../Assets/images/jitoimg.png"),
    warnicon: require("../Assets/images/warnicon.png"),
    toprightarrow: require("../Assets/images/toprightarrow.png"),
    driftimg: require("../Assets/images/driftimg.png"),
    rangeimg: require("../Assets/images/rangeimg.png"),
    searchimg: require("../Assets/images/searchimg.png"),
    clock: require("../Assets/images/clock.png"),
};
