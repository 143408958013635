import React, { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { IoMdClose } from "react-icons/io";
import { GoArrowLeft } from "react-icons/go";

const QuizModal = ({ show, close }) => {

    const [step, setStep] = useState(1);

    const [quiz] = useState([
        {
            answerindex: 1,
            answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris feugiat velit at nisi tincidunt euismod. Donec consectetur leo eu est posuere aliquam."
        },
        {
            answerindex: 1,
            answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
        },
        {
            answerindex: 1,
            answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
        },
        {
            answerindex: 2,
            answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
        },
        {
            answerindex: 2,
            answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
        },
        {
            answerindex: 2,
            answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
        },
        {
            answerindex: 3,
            answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
        },
        {
            answerindex: 3,
            answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
        },
        {
            answerindex: 4,
            answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
        },
        {
            answerindex: 4,
            answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
        },
        {
            answerindex: 4,
            answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
        },
        {
            answerindex: 5,
            answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
        },
    ]);

    const [questions, setQuestions] = useState(1);

    // const [answer, setAnswer] = useState("");
    // useEffect(() => {
    //     const quizanswers = quiz.filter(ai => ai.answerindex === questions);
    //     setAnswer(quizanswers);
    // }, [quiz, questions]);

    if (questions > 5) {
        setQuestions(1);
    }


    const [radios, setRadios] = useState();
    const handlechange = (e) => {
        setRadios(e.target.checked);
    }



    return (
        <>
            <Modal show={show} onHide={close} centered>
                <Modal.Body>
                    <button type="button" className="btn modal_closebtn" onClick={close}>
                        <IoMdClose />
                    </button>
                    <h6 className="modal_head">Test Your Knowledge</h6>
                    {step === 1 &&
                        <>
                            <p className="content text-white lh-24 my-3">
                                How well do you know iwinz? This quiz well take you through 5 questions on iwinz vaults and DeFi risk management. Each question has at least 1 correct answer. Good luck!
                            </p>
                            <button type="button" className="btn sitebtn bgbtn w-100 text-none" onClick={() => setStep(2)}>Task a Quiz</button>
                        </>
                    }
                    {step === 2 &&
                        <div className="quizquestions mt-4">
                            <span className="t-light-grey d-block mb-2 fnt-15">Question {questions}/5</span>
                            <h6 className="quizquestions_head text-white mb-3">What is this strategy ?</h6>
                            <Form>
                                {
                                    quiz.filter(ai => ai.answerindex === questions).map((qz, i) => (
                                        <Form.Group className="form-group">
                                            <Form.Check type="radio" id={`label${i}`} name="question" label={qz.answer} onChange={handlechange}></Form.Check>
                                        </Form.Group>
                                    ))
                                }
                            </Form>
                            <p className="content text-color mt-2">Hint</p>
                            <div className={`mt-2 ${questions > 1 ? "flex align-items-stretch justify-content-between" : "text-end"}`}>
                                {questions > 1 &&
                                    <button type="button" className="btn sitebtn backbtn boxbg" onClick={() => setQuestions(e => e - 1)}>
                                        <GoArrowLeft />
                                    </button>
                                }
                                <button type="button" className="btn sitebtn" disabled={!radios} onClick={() => setQuestions((e => e + 1))}>Next Question</button>
                            </div>
                        </div>
                    }
                </Modal.Body>
            </Modal>
        </>
    )
}

export default QuizModal;