import React, { useState, useEffect } from 'react';
import { Container, Navbar, Nav } from "react-bootstrap";
import Logo from "../Assets/images/logo.svg";
import { CiStar } from "react-icons/ci";
import { LuBell } from "react-icons/lu";
import { useLocation, Link } from "react-router-dom";
import ConnectWalletModal from '../Components/Modals/ConnectWalletModal';

const Header = () => {

    const [menulinks] = useState([
        {
            menu: "Borrow / Lend",
            url: "/",
            urltwo: "/supply-borrow"
        },
        {
            menu: "Liquidity",
            url: "/liquidity",
            urltwo: "/liquidity-detail"
        },
        {
            menu: "Multiply",
            url: "/multiply",
            urltwo: "/multiply-detail",
        },
        {
            menu: "Long / Short",
            url: "/long-short",
            urltwo: "/longshort-detail",
        },
        {
            menu: "Portfolio",
            url: "/dashboard"
        },
    ]);

    const location = useLocation();
    const [scrollheight, Setscrollheight] = useState(window.scrollY);

    useEffect(() => {

        const scrollfunc = () => {
            Setscrollheight(window.scrollY);
        }

        window.addEventListener("scroll", scrollfunc);

        return () => {
            window.removeEventListener("scroll", scrollfunc);
        }
    }, [scrollheight]);

    const [showmodal, setShowmodal] = useState(false);

    return (
        <>
            <header className={`header ${scrollheight > 50 ? "scrolled" : ""}`}>
                <Navbar expand="lg">
                    <Container className="container-90">
                        <Navbar.Brand href="/">
                            <img src={Logo} alt="Iwinz" className="logo" />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav">
                            <span></span>
                            <span></span>
                            <span></span>
                        </Navbar.Toggle>
                        <Navbar.Collapse>
                            <Nav className="mx-auto">
                                {menulinks.map((ml) => (
                                    <Link to={ml.url} className={`nav-link ${(location.pathname === ml.url || location.pathname === ml.urltwo) && "active"}`}>
                                        {ml.menu}
                                    </Link>
                                ))}
                            </Nav>
                            <Nav className="gap-2 mt-3 mt-lg-0 navtwo">
                                <button type="button" className="btn sitebtn icon star">
                                    <div className="d-flex align-items-center gap-2">
                                        <CiStar />
                                        <span>0.00</span>
                                    </div>
                                </button>
                                <button type="button" className="btn sitebtn icon">
                                    <LuBell />
                                </button>
                                <button type="button" className="btn sitebtn" onClick={() => setShowmodal(true)}>
                                    Connect wallet
                                </button>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </header>

            <ConnectWalletModal show={showmodal} close={() => setShowmodal(false)} />
        </>
    )
}

export default Header;