import React, { useState, useEffect } from 'react';
import Header from '../Layouts/Header';
import Footer from '../Layouts/Footer';
import { Container, InputGroup, Form, Row, Col } from 'react-bootstrap';
import { Images } from '../Components/Images';
import BannerDropdown from '../Components/BannerDropdown';
import CoinBox from '../Components/CoinBox';
import MultiplyTable from '../Components/Tables/MultiplyTable';

const Multiply = () => {

    const thead = ["Asset", "Max APY", "Max Multiple", "Liquidity Available", "Supplied"];
    const [table] = useState([
        {
            coinimg: Images.jlp,
            coinname: "JUPSOL",
            tdone: "15.46%",
            tdtwo: "5x",
            tdthree: "$44.22M",
            tdfour: "$198.84M",
        },
        {
            coinimg: Images.hsol,
            coinname: "HSOL",
            tdone: "12.67%",
            tdtwo: "5x",
            tdthree: "$44.22M",
            tdfour: "$4.88M",
        },
        {
            coinimg: Images.msol,
            coinname: "mSOL",
            tdone: "8.50%",
            tdtwo: "5x",
            tdthree: "$44.22M",
            tdfour: "$60.09M",
        },
    ]);

    const [coinbox] = useState([
        {
            coinimg: Images.coinone,
            coinname: "bSOL/SOL Yield Loop",
            badgeone: "Deposit SOL or bSOL",
            badgetwo: "One-Click",
            badgethree: "7.83%",
            badgefour: "$38.89M",
        },
        {
            coinimg: Images.coinone,
            coinname: "JitoSOL/SOL Yield Loop",
            badgeone: "Deposit SOL or bSOL",
            badgetwo: "One-Click",
            badgethree: "7.62%",
            badgefour: "$38.89M",
        },
        {
            coinimg: Images.coinone,
            coinname: "mSOL/SOL Yield Loop",
            badgeone: "Deposit SOL or bSOL",
            badgetwo: "One-Click",
            badgethree: "7.83%",
            badgefour: "$38.89M",
        },
    ]);

    useEffect(() => {
        window.scroll({
            left: 0,
            top: 0,
            behavior: "smooth"
        });
    }, []);

    const [changeinput, setChangeinput] = useState("");

    const handlechange = (e) => {
        let lowercase = e.target.value.toLowerCase();
        setChangeinput(lowercase);
    }

    const filtered = table.filter((tb) => {
        if(changeinput === "") {
            return tb;
        } else {
            return tb.coinname.toLowerCase().includes(changeinput);
        }
    });

    return (
        <>
            <Header />
            <article className="multiply">
                <section className="bannersection innerbanner homebanner">
                    <Container>
                        <BannerDropdown />
                        <p className="content bannercontent text-center">
                        Boost your yield exposure up to 5x with a single click. <span className="text-color">How it works</span>
                        </p>
                        <Row className="mt-5 d-lg-none">
                            <Col xs={12} md={6} className="mb-5">
                                <div>
                                    <img src={Images.multiplyone} alt="" className="img-fluid bannerimgs" />
                                    <p className="content bannerimgscontent">
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos temporibus, odio
                                    </p>
                                </div>
                            </Col>
                            <Col xs={12} md={6} className="mb-5">
                                <div>
                                    <img src={Images.multiplytwo} alt="" className="img-fluid bannerimgs" />
                                </div>
                                <p className="content bannerimgscontent">
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos temporibus, odio
                                </p>
                            </Col>
                            <Col xs={12} md={6} className="mb-5 mb-md-0">
                                <div>
                                    <img src={Images.multiplythree} alt="" className="img-fluid bannerimgs" />
                                </div>
                                <p className="content bannerimgscontent">
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos temporibus, odio
                                </p>
                            </Col>
                            <Col xs={12} md={6}>
                                <div className="flexbox_div mh-auto">
                                    <img src={Images.multiplyfour} alt="" className="img-fluid bannerimgs" width="100" />
                                <p className="content bannerimgscontent">
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos temporibus, odio
                                </p>
                                </div>
                            </Col>
                        </Row>
                        <div className="bannerimgtop d-none d-lg-block">
                            <img src={Images.multiplybanner} alt="" className="img-fluid banner_image" />
                            <p className="content bannerimgscontent bone">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos temporibus, odio
                            </p>
                            <p className="content bannerimgscontent btwo">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos temporibus, odio
                            </p>
                            <p className="content bannerimgscontent bthree">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos temporibus, odio
                            </p>
                            <p className="content bannerimgscontent bfour">
                                Lorem ipsum dolor sit amet consectetur
                            </p>
                        </div>
                    </Container>
                    <div className="bannerbottom"></div>
                </section>
                <section className="coinsbox pb-3">
                    <Container>
                        <CoinBox coinbox={coinbox} type="multiply" />
                    </Container>
                </section>
                <section className="tablesection">
                    <Container>
                        <Form className="mb-5">
                            <InputGroup>
                                <InputGroup.Text>
                                    <img src={Images.search} alt="Search" className="img-fluid" width="20" height="20" />
                                </InputGroup.Text>
                                <Form.Control type="text" placeholder="Search..." onChange={handlechange}></Form.Control>
                            </InputGroup>
                        </Form>
                        <MultiplyTable thead={thead} table={filtered} />
                        <div className="table_underflex d-flex align-items-center justify-content-center gap-2 gap-sm-3 my-4">
                            <div>
                                <p className="content">Total Supplied <strong>$1.47B</strong></p>
                            </div>
                            <div>
                                <span className="t-grey">|</span>
                            </div>
                            <div>
                                <p className="content">Total Borrowed <strong>$527.66M</strong></p>
                            </div>
                            <div>
                                <span className="t-grey">|</span>
                            </div>
                            <div>
                                <p className="content">Borrow / Lend TVL <strong>$938.12M</strong></p>
                            </div>
                        </div>
                    </Container>
                </section>
            </article>

            <Footer />
        </>
    )
}

export default Multiply;