import React from 'react';
import { Form, Modal } from 'react-bootstrap';
import { IoMdClose } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";

const DepositModal = ({ show, close }) => {

    const navigate = useNavigate();

    const handledepo = () => {
        navigate("/");
        Swal.fire({
            position: "center-start",
            title: "Depositing",
            showConfirmButton: false,
            html: `
                <span class="t-light-grey fnt-15">Depositing USDC into the market</span>
                <div class="d-flex align-items-center gap-2 mt-2">
                    <span class="loadspan"></span>
                    <span class="text-white d-block fnt-13">Loading....</span>
                </div>
            `,
            showCloseButton: true,
            timer: 2000
        })
        .then((result) => {
            if(result) {
                Swal.fire({
                    position: "bottom-start",
                    showConfirmButton: false,
                    html: `
                        <div class="d-flex align-items-center gap-2">
                            <input type="checkbox" class="form-check-input" checked />
                            <span class="text-white d-block mt-2">Deposit succeeded!</span>
                        </div>
                        <span class="t-light-grey d-block fnt-14 mt-2">Deposit 10 USDC</span>
                    `,
                    showCloseButton: true,
                    timer: 2000
                });
            }
        });
    }

  return (
    <>
            <Modal show={show} onHide={close} centered className="settings">
                <Modal.Body>
                    <button type="button" className="btn modal_closebtn" onClick={close}>
                        <IoMdClose />
                    </button>
                    <h6 className="modal_head mb-3">One Last Thing...</h6>
                    <span className="text-white fnt-15">Before your first deposit:</span>
                    <ul className="confirmdep my-3">
                        <li>
                            <span>You have read K-Lend’s <span className="text-color">risk documentation</span> and understand the protocol’s risk framework</span>
                        </li>
                        <li>
                            <span>You are aware of the borrower default risk you take when lending your assets</span>
                        </li>
                        <li>
                            <span>You understand K-Lend’s liquidation mechanism and are aware that borrowing can lead to liquidation it: your position is at or above the liquidation LTV, or; if an <span className="text-color">auto-deleverage</span> is triggered for an asset in your position.</span>
                        </li>
                        <li>
                            <span>You understand K-Lend’s <span className="text-color">auto-deleverage</span> mechanism works, for both lend and borrow positions</span>
                        </li>
                        <li>
                            <span>You are aware of, and regularly monitor K-Lend’s live <span className="text-color">risk dashboard</span></span>
                        </li>
                        <li>
                            <span>You have read the <span className="text-color">K-Lend documentation</span></span>
                        </li>
                    </ul>
                    <Form.Check type="checkbox" label="I confirm all of the above" className="text-white"></Form.Check>
                    <button type="button" className="btn sitebtn bgbtn w-100 mt-3" onClick={handledepo}>Complete deposit</button>
                </Modal.Body>
            </Modal>
        </>
  )
}

export default DepositModal;