import React from 'react';
import { Images } from './Images';
import { Row, Col } from "react-bootstrap";

const CoinBox = (props) => {

    const { coinbox, type } = props;

    return (
        <>
            <Row>
                {coinbox.map((cb) => (
                    <Col xs={12} sm={12} md={12} lg={4}>
                        <div className="coinbox">
                            <div className="coinbox_inner">
                                {type === "liquidity" && <img src={cb.coinimglogo} alt="" className="img-fluid coinbox_imgtwo" width="22" height="22" />}
                                <div className="coinbox_imgdiv">
                                    <img src={cb.coinimg} alt="" className="img-fluid coinbox_img" />
                                </div>
                                <div className="coinbox_coinname mt-2">
                                    {cb.coinname}
                                    {type === "liquidity" && <img src={Images.light} alt="" className="img-fluid ms-2" />}
                                </div>
                                <div className="d-flex align-items-center justify-content-center gap-2 my-3">
                                    <div className="coinbox_badge">{cb.badgeone}</div>
                                    <div className="coinbox_badge">{cb.badgetwo}</div>
                                </div>
                                <div className="d-flex align-items-center justify-content-center gap-4">
                                    <span className="coinbox_span">
                                        {type === "liquidity" ? <>7D Fees Apy</> : type === "multiply" ? <>Max Apy</> : type === "longshort" && <>Borrow Rate</>} {cb.badgethree}
                                    </span>
                                    <span className="coinbox_span t-grey">|</span>
                                    <span className="coinbox_span">
                                        {type === "liquidity" ? <>TVL</> : type === "multiply" ? <>Available</> : type === "longshort" && <>Leverage with <img src={Images.usdc} alt="" width="14" height="14" className="img-fluid" /></>} {cb.badgefour}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Col>
                ))}
            </Row>
        </>
    )
}

export default CoinBox;