import React, { useState } from 'react';
import { Form, Modal, Accordion } from 'react-bootstrap';
import { IoMdClose } from "react-icons/io";

const MoreInfoModal = ({ show, close }) => {

    const [quiz] = useState([
        {
            answerindex: 1,
            answer: "SOL multiply positions cannot be liquidated as result of temporary LST depegs."
        },
    ]);

    const [inforiskacc] = useState([
        {
            acchead: "What does this vault do ?",
            acccontent: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Autem dignissimos dolor praesentium repellat laudantium quasi exercitationem ullam in dolorem"
        },
        {
            acchead: "When is this vault profitable ?",
            acccontent: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Autem dignissimos dolor praesentium repellat laudantium quasi exercitationem ullam in dolorem"
        },
        {
            acchead: "How is the APY calculated ?",
            acccontent: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Autem dignissimos dolor praesentium repellat laudantium quasi exercitationem ullam in dolorem"
        },
    ]);

    return (
        <>
            <Modal show={show} onHide={close} centered className="moreinfo">
                <Modal.Body>
                    <button type="button" className="btn modal_closebtn" onClick={close}>
                        <IoMdClose />
                    </button>
                    <h6 className="modal_head">Multiply LTV & Liquidations</h6>
                    <div className="quizquestions mt-4">
                        <Form>
                            {
                                quiz.map((qz, i) => (
                                    <Form.Group className="form-group">
                                        <Form.Check type="radio" id={`label${i}`} name="question" label={qz.answer} className="text-white"></Form.Check>
                                    </Form.Group>
                                ))
                            }
                        </Form>
                    </div>
                    <p className="content text-white lh-24 my-3">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus rhoncus dui sit amet tristique posuere. Vestibulum porttitor pharetra ipsum, non varius diam iaculis luctus.
                    </p>
                    <div className="inforisk">
                        <Accordion defaultActiveKey={false}>
                            {inforiskacc.map((ifac, i) => (
                                <Accordion.Item eventKey={i}>
                                    <Accordion.Header>
                                        {ifac.acchead}
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <p className="content">
                                            {ifac.acccontent}
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))}
                        </Accordion>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default MoreInfoModal;