import React, { useEffect, useState } from 'react';
import Header from '../Layouts/Header';
import Footer from '../Layouts/Footer';
import { Container, Row, Col, Dropdown, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { GoArrowLeft } from "react-icons/go";
import ConnectWalletModal from '../Components/Modals/ConnectWalletModal';
import { Images } from '../Components/Images';
import QuizModal from '../Components/Modals/QuizModal';
import ReactApexChart from "react-apexcharts";
import ApexCharts from 'apexcharts';
import InfoRiskAccordion from '../Components/InfoRiskAccordion';
import Depositbox from '../Components/DepositBox';

const LiquidityDetail = () => {


    const [price] = useState([
        {
            priceone: "DEX",
            pricetwo: "Orca",
            pricethree: Images.boxtoplogoone
        },
        {
            priceone: "Fee Tier",
            pricetwo: "0.01%"
        },
        {
            priceone: "7D Fees APY",
            pricetwo: "1.39%"
        },
        {
            priceone: "oracle"
        },
    ]);

    const [tab, setTab] = useState(1);
    const [show, setShow] = useState(false);

    const [supply] = useState([
        {
            valone: "Boosted Fees APY",
            valtwo: "9.55%"
        },
        {
            valone: "7D Fees APY",
            valtwo: "1.39%",
            valthree: "24H: 1.07%"
        },
        {
            valone: "TVL",
            valtwo: "$36.36M",
            valthree: "Max Capacity: $40.00M"
        },
        {
            valone: "Daily JTO Rewards",
            valtwo: "3.33K JTO",
            valthree: "23.33k JTO for Past 7D"
        }
    ]);

    const [supplytwo] = useState([
        {
            valone: "7D Volume",
            valtwo: "$102.03M",
            valthree: "24H: $12.29M"
        },
        {
            valone: "7D Fees",
            valtwo: "$9.21K",
            valthree: "24H: $1.06k"
        },
        {
            valone: "Position Range",
            valtwo: "1.1222-1.1247",
            valthree: "1.1233 current"
        },
        {
            valone: "Asset Ratio",
            valtwo: "44.50% / 55.50%",
            valthree: "102.94k",
            valthreeimg: Images.jitosol,
            valfour: "114.16k",
            valfourimg: Images.sol
        }
    ]);


    const [dropcon] = useState([
        {
            dimg: Images.sol,
            dcoinname: "SOL"
        },
        {
            dimg: Images.jitosol,
            dcoinname: "jitoSOL"
        },
    ]);

    // const [drop, setDrop] = useState({
    //     dimg: dropcon[0].dimg,
    //     dcoinname: dropcon[0].dcoinname
    // });

    const [farmoverview] = useState([
        {
            valone: "Your Stake",
            valtwo: "$0.00",
            valthree: "0.00 kJitoSOL-SOL"
        },
        {
            valone: "Available to Claim",
            valtwo: "0 JTO",
            valthree: "$0.00"
        },
        {
            valone: "Incentives APY",
            valtwo: "8.24%"
        }
    ]);

    const [supplytabtwo] = useState([
        {
            valone: "Net Value",
            valtwo: "$0.00"
        },
        {
            valone: "PnL",
        },
        {
            valone: "My Asset Ratio",
            valthree: ""
        },
        {
            valone: "Fees Earned",
            valtwo: "$0.00",
            valthree: ""
        }
    ]);


    const [showmodal, setShowmodal] = useState(false);

    // const [supplydet] = useState([
    //     {
    //         valone: "Deposit value",
    //         valtwo: "10 USDC"
    //     },
    //     {
    //         valone: "Loan-to-value-ratio",
    //         valtwo: "0.00% -> 0.00%"
    //     },
    //     {
    //         valone: "Supply APY",
    //         valtwo: "23.08% -> 23.08%"
    //     },
    //     {
    //         valone: "Utilization Ratio",
    //         valtwo: "79.40% -> 79.40%"
    //     },
    //     {
    //         valone: "Asset tier",
    //         valtwo: "Regular"
    //     },
    // ]);

    const [inforiskacc] = useState([
        {
            acchead: "What does this vault do ?",
            acccontent: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Autem dignissimos dolor praesentium repellat laudantium quasi exercitationem ullam in dolorem"
        },
        {
            acchead: "Where does the yield (APY) come from ?",
            acccontent: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Autem dignissimos dolor praesentium repellat laudantium quasi exercitationem ullam in dolorem"
        },
        {
            acchead: "When is this vault profitable ?",
            acccontent: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Autem dignissimos dolor praesentium repellat laudantium quasi exercitationem ullam in dolorem"
        },
        {
            acchead: "When does this vault perform sub-optimally ?",
            acccontent: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Autem dignissimos dolor praesentium repellat laudantium quasi exercitationem ullam in dolorem"
        },
        {
            acchead: "Is there an insurance fund and what happens when there is bad debt ?",
            acccontent: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Autem dignissimos dolor praesentium repellat laudantium quasi exercitationem ullam in dolorem"
        },
    ]);


    const [debt] = useState([
        {
            valone: "Current Deposit",
            valtwo: "$37.29M"
        },
        {
            valone: "Deposit Capacity per Transaction",
            valtwo: "$1.50M"
        },
        {
            valone: "Max Capacity",
            valtwo: "$40.00M"
        },
    ]);

    const [moreinfo, setMoreinfo] = useState(true);

    const [assetdet] = useState([
        {
            coiname: "SOL",
            links: ["Website"],
            desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris laoreet interdum metus eget commodo. Quisque vitae nulla ipsum."
        },
        {
            coiname: "JitoSOL",
            links: ["Website", "SPL Address", "Docs"],
            desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris laoreet interdum metus eget commodo. Quisque vitae nulla ipsum."
        }
    ]);

    const [chartdrop, setChartdrop] = useState("30D");

    const [analyticsone] = useState([
        {
            valone: "TVL",
            valtwo: "$36.88M"
        },
        {
            valone: "7D Volume",
            valtwo: "$105.48M"
        },
        {
            valone: "7D Fees APY",
            valtwo: "1.44%"
        }
    ]);

    const [analyticstwo] = useState([
        {
            valone: "Iwinz strategy",
            valtwo: "+16.13%"
        },
        {
            valone: "Token Pair HODL",
            valtwo: "+16.06%"
        },
        {
            valone: "100% SOL HODL",
            valtwo: "+15.71%"
        },
        {
            valone: "100% JitoSOL HODL",
            valtwo: "+16.44%"
        },
    ]);


    const [chartoption] = useState({
        series: [
            {
                data: [
                    [new Date('03 Mar 2024').getTime(), 16.61],
                    [new Date('06 Mar 2024').getTime(), 6.52],
                    [new Date('10 Apr 2024').getTime(), 12.67],
                    [new Date('01 May 2024').getTime(), 10.52],
                    [new Date('01 Jun 2024').getTime(), 11.92],
                    [new Date('04 Jul 2024').getTime(), 12.20],
                    [new Date('08 Aug 2024').getTime(), 10.23],
                ],
                color: "#BA9E58"
            },
            {
                data: [
                    [new Date('03 Mar 2024').getTime(), 16.61],
                    [new Date('06 Mar 2024').getTime(), 6.52],
                    [new Date('10 Apr 2024').getTime(), 12.67],
                    [new Date('01 May 2024').getTime(), 10.52],
                    [new Date('01 Jun 2024').getTime(), 11.92],
                    [new Date('04 Jul 2024').getTime(), 12.20],
                    [new Date('08 Aug 2024').getTime(), 10.23],
                ],
                color: "#8562FB"
            },
            {
                data: [
                    [new Date('03 Mar 2024').getTime(), 16.61],
                    [new Date('06 Mar 2024').getTime(), 6.52],
                    [new Date('10 Apr 2024').getTime(), 12.67],
                    [new Date('01 May 2024').getTime(), 10.52],
                    [new Date('01 Jun 2024').getTime(), 11.92],
                    [new Date('04 Jul 2024').getTime(), 12.20],
                    [new Date('08 Aug 2024').getTime(), 10.23],
                ],
                color: "#FF0004"
            },
            {
                data: [

                    [new Date('03 Mar 2024').getTime(), 16.61],
                    [new Date('06 Mar 2024').getTime(), 6.52],
                    [new Date('10 Apr 2024').getTime(), 12.67],
                    [new Date('01 May 2024').getTime(), 10.52],
                    [new Date('01 Jun 2024').getTime(), 11.92],
                    [new Date('04 Jul 2024').getTime(), 12.20],
                    [new Date('08 Aug 2024').getTime(), 10.23],
                ],
                color: "#1CBFDA"
            }

        ],

        options: {
            chart: {
                type: 'line',
                id: "mychart",
                toolbar: {
                    show: false
                }
            },
            plotOptions: {
                line: {
                    horizontal: true
                }
            },
            dataLabels: {
                enabled: false,

            },
            title: {
                text: "Performance vs USD",
                align: "left",
                style: {
                    color: "#FFF",
                    fontSize: "18px",
                    fontWeight: 500
                },
                margin: 20
            },

            xaxis: {
                showAlways: true,
                type: 'datetime',
                min: new Date('01 Mar 2024').getTime(),
                max: new Date('01 Aug 2024').getTime(),
                tickAmount: 6,
                labels: {
                    style: {
                        colors: "#fff",
                        fontFamily: '"Roboto", sans-serif',
                        fontWeight: 400,
                        fontSize: "12px"
                    },
                    format: "dd/MM",
                },
                axisBorder: {
                    show: true,
                    color: '#FFFFFF1A',
                    height: 1,
                    width: '100%'
                },
                axisTicks: {
                    show: false
                },

            },
            yaxis: [
                {
                    showAlways: true,
                    axisTicks: {
                        show: true,
                        borderType: 'solid',
                        width: 0,
                    },
                    axisBorder: {
                        show: false,
                        color: "#FFFFFF"
                    },
                    labels: {
                        style: {
                            colors: "#fff",
                            fontFamily: '"Roboto", sans-serif',
                            fontWeight: 400,
                            fontSize: "12px"
                        },
                        formatter: function (val, index) {
                            return `${val.toFixed(2)}%`;
                        },
                    }
                },
            ],
            grid: {
                borderColor: '#FFFFFF1A',
                padding: {
                    left: 30,
                    right: 30
                },
            },
            tooltip: {
                x: {
                    format: 'dd MMM yyyy'
                }
            },
            stroke: {
                curve: "smooth",
                width: [1, 1]
            },
            legend: {
                show: false,
                horizontalAlign: "left",
                offsetX: 100,
                labels: {
                    colors: ["#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"]
                },
                itemMargin: {
                    horizontal: 10,
                    vertical: 30
                },
                markers: {
                    size: 10,
                    shape: 'square',
                    strokeWidth: 1,
                    fillColors: ['#49AFE9', '#66DA26', '#FF0004', '#E91E63'],
                    customHTML: function () {
                        return '<input type="checkbox" class="form-control me-2" checked />'
                    },

                },

            },
            // responsive: [
            //     {
            //         breakpoint: 768,
            //         options: {
            //             plotOptions: {
            //                 bar: {
            //                   horizontal: false
            //                 }
            //             },
            //             legend: {
            //                 position: "top",
            //                 offsetX: 0
            //             },
            //             grid: {
            //                 padding: {
            //                     left: 15,
            //                     right: 15
            //                 },
            //             },
            //         }
            //     }
            // ]
        },
    });

    const [swapcoin, setSwapcoin] = useState("USD");

    const [chartcheck] = useState([
        {
            value: "Iwinz strategy"
        },
        {
            value: "Token Pair HODL"
        },
        {
            value: "100% SOL HODL"
        },
        {
            value: "100% JitoSOL HODL"
        },
    ]);


    const updateData = (timeline) => {
        switch (timeline) {
            case chartcheck[0].value:
                ApexCharts.exec('mychart', 'updateOptions', {
                    xaxis: {
                        min: new Date("01 Mar 2024").getTime(),
                        max: new Date("08 Mar 2024").getTime()
                    }
                }, false, true);
                break;
            case chartcheck[1].value:
                ApexCharts.exec('mychart', 'updateOptions', {
                    xaxis: {
                        min: new Date("01 Mar 2024").getTime(),
                        max: new Date("31 Mar 2024").getTime()
                    }
                }, false, true);
                break;
            case chartcheck[2].value:
                ApexCharts.exec('mychart', 'updateOptions', {
                    xaxis: {
                        min: new Date("01 Mar 2024").getTime(),
                        max: new Date("01 Jun 2024").getTime()
                    }
                }, false, true);
                break;
            case chartcheck[3].value:
                ApexCharts.exec('mychart', 'updateOptions', {
                    xaxis: {
                        min: new Date("01 Mar 2024").getTime(),
                        max: new Date("01 Sep 2024").getTime()
                    }
                }, false, true);
                break;
            default:
                ApexCharts.exec('mychart', 'updateOptions', {
                    xaxis: {
                        min: new Date("01 Mar 2024").getTime(),
                        max: new Date("31 Mar 2024").getTime()
                    }
                }, false, true);
        }
    }

    const [chartoptionfour] = useState({
        series: [
            {
                data: [
                    [new Date('03 Mar 2024').getTime(), 16.61],
                    [new Date('06 Mar 2024').getTime(), 6.52],
                    [new Date('10 Apr 2024').getTime(), 12.67],
                    [new Date('01 May 2024').getTime(), 10.52],
                    [new Date('01 Jun 2024').getTime(), 11.92],
                    [new Date('04 Jul 2024').getTime(), 12.20],
                ],
                color: "#FFD37F"
            },
            {
                data: [
                    [new Date('03 Mar 2024').getTime(), 10.61],
                    [new Date('06 Mar 2024').getTime(), 6.52],
                    [new Date('10 Apr 2024').getTime(), 12.67],
                    [new Date('01 May 2024').getTime(), 10.52],
                    [new Date('01 Jun 2024').getTime(), 14.92],
                    [new Date('04 Jul 2024').getTime(), 10.20],
                ],
                color: "#FF0004"
            },
            {
                data: [
                    [new Date('03 Mar 2024').getTime(), 12.61],
                    [new Date('06 Mar 2024').getTime(), 16.52],
                    [new Date('10 Apr 2024').getTime(), 14.67],
                    [new Date('01 May 2024').getTime(), 15.52],
                    [new Date('01 Jun 2024').getTime(), 13.92],
                    [new Date('04 Jul 2024').getTime(), 1.20],
                ],
                color: "#FFD37F"
            }

        ],

        options: {
            chart: {
                type: 'line',
                id: "mychart",
                toolbar: {
                    show: false
                }
            },
            plotOptions: {
                line: {
                    horizontal: true
                }
            },
            dataLabels: {
                enabled: false,

            },
            title: {
                text: "Historical Ranges & Rebalancing",
                align: "left",
                style: {
                    color: "#FFF",
                    fontSize: "18px",
                    fontWeight: 500
                },
                margin: 20
            },

            xaxis: {
                showAlways: true,
                type: 'datetime',
                min: new Date('01 Mar 2024').getTime(),
                max: new Date('01 Aug 2024').getTime(),
                tickAmount: 6,
                labels: {
                    style: {
                        colors: "#fff",
                        fontFamily: '"Roboto", sans-serif',
                        fontWeight: 400,
                        fontSize: "12px"
                    },
                    format: "dd/MM",
                },
                axisBorder: {
                    show: true,
                    color: '#FFFFFF1A',
                    height: 1,
                    width: '100%'
                },
                axisTicks: {
                    show: false
                },

            },
            yaxis: [
                {
                    showAlways: true,
                    axisTicks: {
                        show: true,
                        borderType: 'solid',
                        width: 0,
                    },
                    axisBorder: {
                        show: false,
                        color: "#FFFFFF"
                    },
                    labels: {
                        style: {
                            colors: "#fff",
                            fontFamily: '"Roboto", sans-serif',
                            fontWeight: 400,
                            fontSize: "12px"
                        },
                        // formatter: function (val, index) {
                        //     return `${val.toFixed(2)}%`;
                        // },
                    }
                },
            ],
            grid: {
                borderColor: '#FFFFFF1A',
                padding: {
                    left: 30,
                    right: 30
                },
            },
            tooltip: {
                x: {
                    format: 'dd MMM yyyy'
                }
            },
            stroke: {
                curve: "smooth",
                width: [1, 1]
            },
            legend: {
                show: false,
                horizontalAlign: "left",
                offsetX: 100,
                labels: {
                    colors: ["#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"]
                },
                itemMargin: {
                    horizontal: 10,
                    vertical: 30
                },
                markers: {
                    size: 10,
                    shape: 'square',
                    strokeWidth: 1,
                    fillColors: ['#49AFE9', '#66DA26', '#FF0004', '#E91E63'],
                    customHTML: function () {
                        return '<input type="checkbox" class="form-control me-2" checked />'
                    },

                },

            },
            // responsive: [
            //     {
            //         breakpoint: 768,
            //         options: {
            //             plotOptions: {
            //                 bar: {
            //                   horizontal: false
            //                 }
            //             },
            //             legend: {
            //                 position: "top",
            //                 offsetX: 0
            //             },
            //             grid: {
            //                 padding: {
            //                     left: 15,
            //                     right: 15
            //                 },
            //             },
            //         }
            //     }
            // ]
        },
    });

    const [chartoptionthree] = useState({
        series: [
            {
                data: [
                    [new Date('03 Mar 2024').getTime(), 11.61],
                    [new Date('04 Mar 2024').getTime(), 12.61],
                    [new Date('05 Mar 2024').getTime(), 13.61],
                    [new Date('06 Mar 2024').getTime(), 6.52],
                    [new Date('07 Mar 2024').getTime(), 6.52],
                    [new Date('08 Mar 2024').getTime(), 6.52],
                    [new Date('09 Mar 2024').getTime(), 6.52],
                    [new Date('10 Mar 2024').getTime(), 11.61],
                    [new Date('11 Mar 2024').getTime(), 12.61],
                    [new Date('12 Mar 2024').getTime(), 13.61],
                    [new Date('13 Mar 2024').getTime(), 6.52],
                    [new Date('14 Mar 2024').getTime(), 6.52],
                    [new Date('15 Mar 2024').getTime(), 6.52],
                    [new Date('16 Mar 2024').getTime(), 6.52],
                    [new Date('17 Mar 2024').getTime(), 3.52],
                    [new Date('18 Mar 2024').getTime(), 6.52],
                    [new Date('19 Mar 2024').getTime(), 5.52],
                    [new Date('20 Mar 2024').getTime(), 6.52],
                    [new Date('21 Mar 2024').getTime(), 5.52],
                    [new Date('22 Mar 2024').getTime(), 7.52],
                    [new Date('23 Mar 2024').getTime(), 6.52],
                    [new Date('24 Mar 2024').getTime(), 6.52],
                    [new Date('25 Mar 2024').getTime(), 6.52],
                    [new Date('26 Mar 2024').getTime(), 6.52],
                    [new Date('01 Apr 2024').getTime(), 12.67],
                    [new Date('02 Apr 2024').getTime(), 10.67],
                    [new Date('03 Apr 2024').getTime(), 8.67],
                    [new Date('04 Apr 2024').getTime(), 6.67],
                    [new Date('01 May 2024').getTime(), 6.67],
                    [new Date('02 May 2024').getTime(), 8.67],
                    [new Date('03 May 2024').getTime(), 10.67],
                    [new Date('04 May 2024').getTime(), 12.67],
                    [new Date('01 Jun 2024').getTime(), 6.67],
                    [new Date('02 Jun 2024').getTime(), 8.67],
                    [new Date('03 Jun 2024').getTime(), 10.67],
                    [new Date('04 Jun 2024').getTime(), 12.67],
                    [new Date('05 Jun 2024').getTime(), 6.67],
                    [new Date('06 Jun 2024').getTime(), 8.67],
                    [new Date('07 Jun 2024').getTime(), 10.67],
                    [new Date('08 Jun 2024').getTime(), 12.67],
                    [new Date('09 Jun 2024').getTime(), 6.67],
                    [new Date('10 Jun 2024').getTime(), 8.67],
                    [new Date('11 Jun 2024').getTime(), 12.67],
                    [new Date('12 Jun 2024').getTime(), 7.67],
                ],
                color: "#FFD37F",
                type: 'column',
            },
            {
                data: [
                    [new Date('03 Mar 2024').getTime(), 10.61],
                    [new Date('06 Mar 2024').getTime(), 6.52],
                    [new Date('10 Apr 2024').getTime(), 12.67],
                    [new Date('01 May 2024').getTime(), 10.52],
                    [new Date('01 Jun 2024').getTime(), 11.92],
                    [new Date('04 Jun 2024').getTime(), 10.20],
                ],
                color: "#FF0004"
            },

        ],

        options: {
            chart: {
                type: 'line',
                id: "mychart",
                toolbar: {
                    show: false
                }
            },
            plotOptions: {
                line: {
                    horizontal: true
                }
            },
            dataLabels: {
                enabled: false,

            },
            title: {
                text: "APY & Fee",
                align: "left",
                style: {
                    color: "#FFF",
                    fontSize: "18px",
                    fontWeight: 500
                },
                margin: 20
            },

            xaxis: {
                showAlways: true,
                type: 'datetime',
                min: new Date('01 Mar 2024').getTime(),
                max: new Date('01 Jul 2024').getTime(),
                tickAmount: 6,
                labels: {
                    style: {
                        colors: "#fff",
                        fontFamily: '"Roboto", sans-serif',
                        fontWeight: 400,
                        fontSize: "12px"
                    },
                    format: "dd/MM",
                },
                axisBorder: {
                    show: true,
                    color: '#FFFFFF1A',
                    height: 1,
                    width: '100%'
                },
                axisTicks: {
                    show: false
                },

            },
            yaxis: [
                {
                    showAlways: true,
                    axisTicks: {
                        show: true,
                        borderType: 'solid',
                        width: 0,
                    },
                    axisBorder: {
                        show: false,
                        color: "#FFFFFF"
                    },
                    labels: {
                        style: {
                            colors: "#fff",
                            fontFamily: '"Roboto", sans-serif',
                            fontWeight: 400,
                            fontSize: "12px"
                        },
                        formatter: function (val, index) {
                            return `${val.toFixed(1)}%`;
                        },
                    }
                },
                {
                    showAlways: true,
                    opposite: true,
                    axisTicks: {
                        show: true
                    },
                    axisBorder: {
                        show: false,
                        color: "#247BA0"
                    },
                    labels: {
                        style: {
                            colors: "#fff",
                            fontFamily: '"Roboto", sans-serif',
                            fontWeight: 400,
                            fontSize: "12px"
                        },
                        formatter: function (val, index) {
                            return `$${val.toFixed(2)}K`;
                        }
                    }
                }
            ],
            grid: {
                borderColor: '#FFFFFF1A',
                padding: {
                    left: 30,
                    right: 30
                },
            },
            tooltip: {
                x: {
                    format: 'dd MMM yyyy'
                }
            },
            stroke: {
                curve: "smooth",
                width: [1, 1]
            },
            legend: {
                show: false,
                horizontalAlign: "left",
                offsetX: 100,
                labels: {
                    colors: ["#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"]
                },
                itemMargin: {
                    horizontal: 10,
                    vertical: 30
                },
                markers: {
                    size: 10,
                    shape: 'square',
                    strokeWidth: 1,
                    fillColors: ['#49AFE9', '#66DA26', '#FF0004', '#E91E63'],
                    customHTML: function () {
                        return '<input type="checkbox" class="form-control me-2" checked />'
                    },

                },

            },
            // responsive: [
            //     {
            //         breakpoint: 768,
            //         options: {
            //             plotOptions: {
            //                 bar: {
            //                   horizontal: false
            //                 }
            //             },
            //             legend: {
            //                 position: "top",
            //                 offsetX: 0
            //             },
            //             grid: {
            //                 padding: {
            //                     left: 15,
            //                     right: 15
            //                 },
            //             },
            //         }
            //     }
            // ]
        },
    });

    useEffect(() => {
        window.scrollTo({
            left: 0,
            top: 0,
            behavior: "smooth"
        });
    }, []);

    const [tabbtn] = useState([
        {
            val: "Overview"
        },
        {
            val: "My Position"
        },
        {
            val: "Analytics"
        },
        {
            val: "Info & Risk"
        },
    ]);

    return (
        <>
            <Header />

            <article className="innerpages">
                <section className="liquiditydetail">
                    <Container>
                        <div className="d-block d-lg-flex flex justify-content-between gap-2">
                            <div className="flex gap-2 mb-4 mb-lg-0">
                                <Link to="/liquidity" className="btn sitebtn backbtn bgbtn"><GoArrowLeft /></Link>
                                <img src={Images.twocoins} alt="" className="img-fluid topheadimg" />
                                <p className="tophead">JitoSoL-SOL Liquidity</p>
                                <img src={Images.light} alt="" className="img-fluid" width="10" />
                            </div>
                            <div className="boxbg rightboxbg">
                                <div className="flex gridb">
                                    {price.map((pr) => (
                                        <>
                                            <p className={`content t-light-grey ${pr.priceone.toLowerCase().includes("dex") && "align-items-sm-center gap-2"}`}>
                                                <span className="t-light-grey text-capitalize">
                                                    {pr.priceone}
                                                </span>
                                                {pr.priceone.toLowerCase().includes("dex") &&
                                                    <>
                                                        <img src={pr.pricethree} alt="" width="20" className="img-fluid" />
                                                    </>
                                                }
                                                <span className={`text-white ${pr.priceone.toLowerCase().includes("dex") ? "" : "ms-sm-2"}`}>{pr.pricetwo}</span>
                                            </p>
                                            {pr.pricetwo && <span className="t-light-grey mx-2">|</span>}
                                        </>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="d-sm-flex align-items-sm-center justify-content-sm-between mt-4 mt-sm-5 sm-flex">
                            <div className="tabs boxbg p-0 mb-4 mb-sm-0">
                                {tabbtn.map((tb, i) => (
                                    <button type="button" className={`btn tabbtn ${tab === (i + 1) && "bgbtn"}`} onClick={() => setTab((i + 1))}>{tb.val}</button>
                                ))}
                                {/* <button type="button" className={`btn tabbtn ${tab === 1 && "bgbtn"}`} onClick={() => setTab(1)}>Overview</button>
                                <button type="button" className={`btn tabbtn ${tab === 2 && "bgbtn"}`} onClick={() => setTab(2)}>My Position</button>
                                <button type="button" className={`btn tabbtn ${tab === 3 && "bgbtn"}`} onClick={() => setTab(3)}>Analytics</button>
                                <button type="button" className={`btn tabbtn ${tab === 4 && "bgbtn"}`} onClick={() => setTab(4)}>Info & Risk</button> */}
                            </div>
                            {tab === 1 &&
                                <button type="button" className="btn boxbg text-white text-capitalize" onClick={() => setShow(true)}>
                                    <img src={Images.quiz} alt="" className="img-fluid" width="18" />
                                    <span className="ms-2">quiz</span>
                                </button>
                            }
                            {tab === 3 &&
                                <Dropdown className="chartdrop text-center text-sm-start">
                                    <Dropdown.Toggle className="fnt-14">
                                        <span className="t-light-grey">Period:</span> {chartdrop}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="mt-2">
                                        <Dropdown.Item as="button" onClick={() => setChartdrop("7D")}>
                                            7 days
                                        </Dropdown.Item>
                                        <Dropdown.Item as="button" onClick={() => setChartdrop("30D")}>
                                            30 days
                                        </Dropdown.Item>
                                        <Dropdown.Item as="button" onClick={() => setChartdrop("90D")}>
                                            90 days
                                        </Dropdown.Item>
                                        <Dropdown.Item as="button" onClick={() => setChartdrop("6M")}>
                                            6 Months
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            }
                        </div>
                        <Row className="mt-4 f-column">
                            {tab === 1 &&
                                <Col xs={12} sm={12} md={12} lg={8} className="positionboxes">
                                    <div className="innerbg mb-3 p-3">
                                        <div className="innerbgdiv">
                                            <Row className="align-items-center">
                                                <Col xs={12} sm={12} md={3} lg={3} className="text-center mb-4 mb-md-0">
                                                    <img src={Images.jitoimg} alt="" className="img-fluid conimg" />
                                                </Col>
                                                <Col xs={12} sm={12} md={9} lg={9}>
                                                    <h5 className="h5 conhead text-color fw-400">3 Months of $JTO Rewards!</h5>
                                                    <p className="content text-white mb-1 lh-24">
                                                        Provide liquidity to the JitoSOL-SOL Vault to earn $JTO for the next three months. Claim your $JTO in real-time. Withdraw at any time.
                                                    </p>
                                                    <Link to="/" className="text-color fnt-15 text-decoration-none">Learn More</Link>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                    <div className="innerbg mb-3 p-3">
                                        <div className="innerbgdivrow">
                                            <div className="gridbox">
                                                {supply.map((sy) => (
                                                    <div>
                                                        <div className="innerbox">
                                                            <span className="mb-2 t-light-grey text-capitalize">
                                                                {sy.valone.toLowerCase().includes("7d") &&
                                                                    <img src={Images.warnicon} alt="" className="img-fluid" width="20" />} {sy.valone}
                                                            </span>
                                                            <span className={`mb-2 text-white fnt-16 ${sy.valone.toLowerCase().includes("apy") && "text-color-light bt-line bt-line-color"}`}>{sy.valtwo}</span>
                                                            <span className="t-light-grey">{sy.valthree}</span>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="innerbg mb-3 p-3">
                                        <div className="innerbgdiv">
                                            <Row className="align-items-center">
                                                <Col xs={12} sm={12} md={9} lg={9} className="mb-4 mb-md-0">
                                                    <h5 className="h5 conhead text-white fw-400">
                                                        Strategy:
                                                        <span className="text-color ms-1">Drift</span>
                                                    </h5>
                                                    <p className="content text-white my-3 lh-24">
                                                        Earn staking yield and trading fees with SOL exposure. Iwinz algorithms set and rebalance the trading range, and auto-compound fees and rewards.
                                                    </p>
                                                    <Link to="/" className="text-color fnt-15 text-decoration-none">
                                                        <div className="toprightarrowlink">
                                                            Learn More
                                                            <img src={Images.toprightarrow} alt="" className="img-fluid" width="7" />
                                                        </div>
                                                    </Link>
                                                </Col>
                                                <Col xs={12} sm={12} md={3} lg={3} className="text-center">
                                                    <img src={Images.driftimg} alt="" className="img-fluid conimg" />
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                    <div className="innerbg mb-3 p-3">
                                        <div className="innerbgdivrow">
                                            <div className="gridbox">
                                                {supplytwo.map((sy) => (
                                                    <div>
                                                        <div className="innerbox">
                                                            <span className="mb-2 t-light-grey text-capitalize">
                                                                {sy.valone}
                                                            </span>
                                                            <span className={`mb-2 text-white fnt-16 ${sy.valone.toLowerCase().includes("apy") && "text-color-light bt-line bt-line-color"}`}>{sy.valtwo}</span>
                                                            <span className={`${sy.valone.toLowerCase().includes("position") ? "text-color" : "t-light-grey"}`}>
                                                                {sy.valone.toLowerCase().includes("asset") ?
                                                                    <div className="flex gap-1">
                                                                        <img src={sy.valthreeimg} alt="" className="img-fluid" width="15" />
                                                                        <span className="t-light-grey">{sy.valthree}</span>
                                                                        <span className="t-light-grey">/</span>
                                                                        <img src={sy.valfourimg} alt="" className="img-fluid" width="15" />
                                                                        <span className="t-light-grey">{sy.valfour}</span>
                                                                    </div> :
                                                                    sy.valthree
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="mt-3 text-center">
                                                <img src={Images.rangeimg} alt="" className="img-fluid" />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            }
                            {tab === 2 &&
                                <Col xs={12} sm={12} md={12} lg={8}>
                                    <div className="innerbg mb-3 p-3">
                                        <h6 className="innerbg_head">Farm Overview</h6>
                                        <Row className="align-items-center">
                                            <Col xs={12} md={12} lg={9} className="mb-3 mb-lg-0">
                                                <div className="innerbgdiv align-items-stretch gap-md-4">
                                                    {farmoverview.map((ln) => (
                                                        <div className="innerbox">
                                                            <span className={`mb-2 t-light-grey text-capitalize ${ln.valone.toLowerCase().includes("penalty") && "bt-line"}`}>{ln.valone}</span>
                                                            <span className="fnt-16 text-white d-block mb-2">{ln.valtwo}</span>
                                                            <span className="fnt-15 text-white">{ln.valthree}</span>
                                                        </div>
                                                    ))}
                                                </div>
                                            </Col>
                                            <Col xs={12} md={12} lg={3}>
                                                <div className="text-center text-lg-end">
                                                    <button type="button" className="btn sitebtn">claim all</button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="innerbg mb-3 p-3">
                                        <div className="innerbgdivrow">
                                            <div className="gridbox">
                                                {supplytabtwo.map((sy) => (
                                                    <div>
                                                        <div className="innerbox">
                                                            <span className="mb-2 t-light-grey text-capitalize">
                                                                {sy.valone}
                                                            </span>
                                                            <span className={`${sy.valone.toLowerCase().includes("fees") && "bt-line bt-line-color"} mb-2 text-white fnt-16`}>
                                                                {sy.valtwo ? sy.valtwo : "-"}
                                                            </span>
                                                            {sy.valthree === "" &&
                                                                <span className="t-light-grey">
                                                                    {sy.valthree ? sy.valthree : "-"}
                                                                </span>
                                                            }
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="innerbg p-3 posdata">
                                        <img src={Images.dashboardnodata} alt="" className="img-fluid m-auto" width="120" />
                                        <div className="my-4">
                                            <p className="content text-white">Make a deposit to view data</p>
                                            <p className="content text-white">Connect wallet to see performance of your position</p>
                                        </div>
                                        <button type="button" className="btn sitebtn bgbtn" onClick={() => setShowmodal(true)}>Connect Wallet</button>
                                    </div>
                                </Col>
                            }
                            {(tab === 1 || tab === 2) &&
                                <Col xs={12} sm={12} md={12} lg={4} className="mb-4 mb-lg-0">
                                    <Depositbox dropcon={dropcon} rangename="liquidity" />
                                    {/* <div className="innerbg p-3">
                                        <div className="liquidityrightbox usemarketrightbox">
                                            <div className="boxbg">
                                                <div className="flex justify-content-between">
                                                    <span className="text-white">Your supply</span>
                                                    <span className="t-light-grey fnt-14 smbg">~$0.00</span>
                                                </div>
                                                <div className="flex justify-content-between my-4">
                                                    <div>
                                                        <Dropdown>
                                                            <Dropdown.Toggle className="text-capitalize fnt-15">
                                                                <img src={drop.dimg} alt="" className="img-fluid" />
                                                                <span>{drop.dcoinname}</span>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu className="mt-2">
                                                                {dropcon.map((dp) => (
                                                                    <Dropdown.Item as="button" className="text-capitalize" onClick={() => setDrop(dp)}>
                                                                        <img src={dp.dimg} alt="" className="img-fluid" />
                                                                        <span>{dp.dcoinname}</span>
                                                                    </Dropdown.Item>
                                                                ))}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                    <div>
                                                        <Form.Control type="text" placeholder="0" className="smbg"></Form.Control>
                                                    </div>
                                                </div>
                                                <div className="flex justify-content-between">
                                                    <div>
                                                        <span className="t-light-grey text-capitalize">
                                                            Available: 0 {drop.dcoinname}
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span className="text-white smbg text-capitalize me-2">half</span>
                                                        <span className="text-white smbg text-capitalize">max</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <p className="content text-white my-3">
                                                Please, connect your wallet to see the balance
                                            </p>
                                            <button type="button" className="btn sitebtn bgbtn w-100" onClick={() => setShowmodal(true)}>Connect Wallet</button>
                                        </div>
                                    </div> */}
                                </Col>
                            }
                            {tab === 3 &&
                                <Col xs={12} sm={12}>
                                    <div className="innerbg mb-3 p-3 analyticsone">
                                        <div className={`innerbgdivrow innerbgdiv ${analyticsone.length > 3 ? "gridb" : "flex justify-content-between"}`}>
                                            {analyticsone.map((sy) => (
                                                <div className="innerbox">
                                                    <span className="mb-2 t-light-grey text-capitalize">
                                                        {sy.valone}
                                                    </span>
                                                    <span className={`${sy.valone.toLowerCase().includes("fees") && "bt-line bt-line-color"} text-white fnt-16`}>
                                                        {sy.valtwo ? sy.valtwo : "-"}
                                                    </span>
                                                    {sy.valthree === "" &&
                                                        <span className="t-light-grey">
                                                            {sy.valthree ? sy.valthree : "-"}
                                                        </span>
                                                    }
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="innerbg mb-3 p-3 chartonebox chartcheckbox">
                                        <div id="chartone">
                                            <ReactApexChart options={chartoption.options} series={chartoption.series} type="line" height="350" />
                                        </div>
                                        <div className="flex gap-2 fnt-14 coinst">
                                            <span className={`text-white ${swapcoin === "USD" && "text-color"}`} onClick={() => setSwapcoin("USD")}>USD</span>
                                            <span className="t-grey">|</span>
                                            <span className={`text-white ${swapcoin === "SOL" && "text-color"}`} onClick={() => setSwapcoin("SOL")}>SOL</span>
                                        </div>
                                        <Form className="flex gap-4 chartcheck">
                                            {chartcheck.map((ck, i) => (
                                                <Form.Check type="checkbox" label={ck.value} className={`chartcheck${i}`} onClick={() => updateData(ck.value)}></Form.Check>
                                            ))}
                                        </Form>
                                    </div>
                                    <div className="innerbg mb-3 p-3 analyticsone">
                                        <div className="innerbgdivrow">
                                            <div className="gridbox">
                                                {analyticstwo.map((sy, i) => (
                                                    <div>
                                                        <div className="innerbox">
                                                            <div className="flex gap-2 mb-2">
                                                                <span className={`circlebox circlebox${i}`}></span>
                                                                <span className="t-light-grey text-capitalize">
                                                                    {sy.valone}
                                                                </span>
                                                            </div>
                                                            <span className={`${sy.valone.toLowerCase().includes("fees") && "bt-line bt-line-color"} text-white fnt-16`}>
                                                                {sy.valtwo ? sy.valtwo : "-"}
                                                            </span>
                                                            {sy.valthree === "" &&
                                                                <span className="t-light-grey">
                                                                    {sy.valthree ? sy.valthree : "-"}
                                                                </span>
                                                            }
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="innerbg mb-3 p-3 chartonebox">
                                        <div id="chartthree">
                                            <ReactApexChart options={chartoptionthree.options} series={chartoptionthree.series} type="line" height="300" />
                                        </div>
                                        <div className="flex gap-3 flex-column flex-sm-row chartcheck">
                                            <div>
                                                <img src={Images.borrowicon} alt="" className="img-fluid" width="20" />
                                                <span className="text-white fnt-14 ms-2">Cumulative Fees & Rewards</span>
                                            </div>
                                            <div>
                                                <img src={Images.supplyicon} alt="" className="img-fluid" width="20" />
                                                <span className="text-white fnt-14 ms-2">24h APY</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="innerbg mb-3 p-3 chartonebox">
                                        <div id="chartfour">
                                            <ReactApexChart options={chartoptionfour.options} series={chartoptionfour.series} type="line" height="350" />
                                        </div>
                                        <div className="flex gap-3 flex-column flex-sm-row chartcheck">
                                            <div>
                                                <img src={Images.borrowicon} alt="" className="img-fluid" width="20" />
                                                <span className="text-white fnt-14 ms-2">Pool Price</span>
                                            </div>
                                            <div>
                                                <img src={Images.supplyicon} alt="" className="img-fluid" width="20" />
                                                <span className="text-white fnt-14 ms-2">Strategy Range</span>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            }
                            {tab === 4 &&
                                <>
                                    <Col xs={12} md={12} lg={8}>
                                        <InfoRiskAccordion data={inforiskacc} />
                                        <div className="innerbg p-4 mt-3">
                                            <h6 class="rightbox_head">Create Your Own Strategy</h6>
                                            <div className="flex gap-5 mt-4">
                                                <Link to="/" className="text-color fnt-15 text-decoration-none">
                                                    <div className="toprightarrowlink">
                                                        Go to Vault Creator
                                                        <img src={Images.toprightarrow} alt="" className="img-fluid" width="7" />
                                                    </div>
                                                </Link>
                                                <Link to="/" className="text-color fnt-15 text-decoration-none">
                                                    <div className="toprightarrowlink">
                                                        Use Iwinz SDK
                                                        <img src={Images.toprightarrow} alt="" className="img-fluid" width="7" />
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={12} lg={4} className="mb-4 mb-lg-0">
                                        <div className="inforiskrightbox">
                                            <div className="innerbg p-3 mb-3">
                                                <div className="">
                                                    <p className="mb-2 fnt-15 text-white text-capitalize">Vault Info</p>
                                                    <div className="flex gap-5">
                                                        <Link to="/" className="text-color fnt-15 text-decoration-none">
                                                            <div className="toprightarrowlink">
                                                                Strategy address
                                                                <img src={Images.toprightarrow} alt="" className="img-fluid" width="7" />
                                                            </div>
                                                        </Link>
                                                        <Link to="/" className="text-color fnt-15 text-decoration-none">
                                                            <div className="toprightarrowlink">
                                                                Pool address
                                                                <img src={Images.toprightarrow} alt="" className="img-fluid" width="7" />
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                                <p className="mb-0 mt-3 fnt-15 text-white text-capitalize">
                                                    Vault Capacity
                                                </p>
                                                <div className="supplydet mt-2 inforisk_det">
                                                    {debt.map((dt) => (
                                                        <div className="flex justify-content-between">
                                                            <span className="t-light-grey fnt-14">{dt.valone}</span>
                                                            <span className="text-white fnt-14">
                                                                {dt.valtwo}
                                                            </span>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className="text-center mt-3">
                                                    <Dropdown>
                                                        <Dropdown.Toggle className="text-capitalize fnt-15 text-color" onClick={() => setMoreinfo(!moreinfo)}>
                                                            {moreinfo ? "More" : "Less"} Info
                                                        </Dropdown.Toggle>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                            <div className="innerbg p-3">
                                                <p className="mb-3 fnt-15 text-white text-capitalize">Asset Details</p>
                                                {assetdet.map((at) => (
                                                    <>
                                                        <div className="assetdiv">
                                                            <p className="mb-2 fnt-15 text-white">{at.coiname}</p>
                                                            <div className="flex gap-5">
                                                                {at.links.map((atli) => (
                                                                    <Link to="/" className="text-color fnt-15 text-decoration-none">
                                                                        <div className="toprightarrowlink">
                                                                            {atli}
                                                                            <img src={Images.toprightarrow} alt="" className="img-fluid" width="7" />
                                                                        </div>
                                                                    </Link>
                                                                ))
                                                                }
                                                            </div>
                                                            <p className="content text-white fnt-14 mt-2 lh-24">
                                                                {at.desc}
                                                            </p>
                                                            <hr className="bt-line" />
                                                        </div>
                                                    </>
                                                ))}
                                            </div>
                                        </div>
                                    </Col>
                                </>
                            }
                        </Row>
                    </Container>
                </section>
            </article>

            <Footer />

            <QuizModal show={show} close={() => setShow(false)} />
            <ConnectWalletModal show={showmodal} close={() => setShowmodal(false)} />
        </>
    )
}

export default LiquidityDetail;