import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link, useLocation } from "react-router-dom";

const BannerDropdown = () => {

    const [dropdown] = useState([
        {
            drophead: "Borrow / Lend",
            dropdesc: "Supply assets to earn yield and borrow against collateral",
            url: "/"
        },
        {
            drophead: "Liquidity",
            dropdesc: "Earn automated fees & rewards by deploying assets into concentrated liquidity DEXs",
            url: "/liquidity"
        },
        {
            drophead: "Multiply",
            dropdesc: "Boost your SOL staking yield up to 10x with a single click",
            url: "/multiply"
        },
        {
            drophead: "Long / short",
            dropdesc: "Increase your exposure to any crypto asset with one-click leverage",
            url: "/long-short"
        },
    ]);

    const location = useLocation();
    // const [dropheading, setDropheading] = useState(drophead[0].drophead);

    // useEffect(() => {
    //     const drophead = dropdown.filter((droph) => location.pathname === droph.url);
    //     if(drophead) {
    //         setDropheading(drophead[0].drophead);
    //     }
    // }, [dropheading]);

    const drophead = dropdown.filter((droph) => location.pathname === droph.url);
    const [dropheading] = useState(drophead[0].drophead);
    

    return (
        <div className="dropoptions">
            <Dropdown>                
                <Dropdown.Toggle>
                    {dropheading}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {dropdown.map((dd) => (
                        <Link to={dd.url} className={`dropdown-item ${location.pathname === dd.url && "active"}`}>
                            <p className="dropitemhead">{dd.drophead}</p>
                            <p className="content">{dd.dropdesc}</p>
                        </Link>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

export default BannerDropdown;