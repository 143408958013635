import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import flogo from "../Assets/images/logo.svg";
import { Images } from '../Components/Images';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <>
            <footer className="footer">
                <Container className="container-90">
                    <div className="footerrow">
                        <Row>
                            <Col xs={12} sm={6} md={6} lg={3} className="mb-5 mb-lg-0">
                                <img src={flogo} alt="Footer Logo" className="flogo" />
                                <div className="d-flex align-items-center gap-3 mt-4 sociallinks">
                                    <div>
                                        <Link to="/">
                                            <img src={Images.discord} alt="Discord" width="40" height="40" />
                                        </Link>                                        
                                    </div>
                                    <div>
                                        <Link to="/">
                                            <img src={Images.twitter} alt="Twitter" width="40" height="40" />
                                        </Link>                                        
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} sm={6} md={6} lg={3} className="mb-5 mb-lg-0">
                                <h6 className="foothead">About</h6>
                                <ul className="footlinks">
                                    <li>
                                        <Link to="/" className="footlink">Documentation</Link>
                                    </li>
                                    <li>
                                        <Link to="/" className="footlink">Terms</Link>
                                    </li>
                                </ul>
                            </Col>
                            <Col xs={12} sm={6} md={6} lg={3} className="mb-5 mb-md-0">
                                <h6 className="foothead">Products</h6>
                                <ul className="footlinks">
                                    <li>
                                        <Link to="/" className="footlink">Lending</Link>
                                    </li>
                                    <li>
                                        <Link to="/liquidity" className="footlink">Liquidity</Link>
                                    </li>
                                    <li>
                                        <Link to="/multiply" className="footlink">Multiply</Link>
                                    </li>
                                    <li>
                                        <Link to="/" className="footlink">Leverage</Link>
                                    </li>
                                    <li>
                                        <Link to="/" className="footlink">Creator Vaults</Link>
                                    </li>
                                </ul>
                            </Col>
                            <Col xs={12} sm={6} md={6} lg={3}>
                                <h6 className="foothead">Connect</h6>
                                <ul className="footlinks">
                                    <li>
                                        <Link to="/" className="footlink">Discord</Link>
                                    </li>
                                    <li>
                                        <Link to="/" className="footlink">x</Link>
                                    </li>
                                    <li>
                                        <Link to="/" className="footlink">Contact</Link>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </div>
                    <div className="footertext text-center py-4">
                        <p className="content">© 2024 iwinz. All rights reserved</p>
                    </div>
                </Container>
            </footer>
        </>
    )
}

export default Footer;