import React from 'react';
import { Form, Modal } from 'react-bootstrap';
import { IoMdClose } from "react-icons/io";
import { Images } from '../Images';
import { Link } from 'react-router-dom';

const ConnectWalletModal = ({ show, close }) => {

    return (
        <>
            <Modal show={show} onHide={close} centered>
                <Modal.Body>
                    <button type="button" className="btn modal_closebtn" onClick={close}>
                        <IoMdClose />
                    </button>
                    <h6 className="modal_head">Connect a wallet</h6>
                    <div className="modal_cowallets">
                        <button type="button" className="btn modal_walletbox mb-2">
                            <p className="modal_walletname content">Phantom</p>
                            <img src={Images.phantom} alt="" className="img-fluid modal_walletimg" />
                        </button>
                        <button type="button" className="btn modal_walletbox mb-2">
                            <p className="modal_walletname content">solflare</p>
                            <img src={Images.solflare} alt="" className="img-fluid modal_walletimg" />
                        </button>
                        {/* <button type="button" className="btn modal_walletbox mb-2">
                            <p className="modal_walletname content">Coinbase Wallet</p>
                            <img src={Images.coinbase} alt="" className="img-fluid modal_walletimg" />
                        </button>
                        <button type="button" className="btn modal_walletbox">
                            <p className="modal_walletname content">Torus</p>
                            <img src={Images.torus} alt="" className="img-fluid modal_walletimg" />
                        </button> */}
                    </div>
                    <div className="modal_track">
                        <p className="content text-white mb-2">Track wallet balance in read-only mode</p>
                        <Form>
                            <Form.Control type="text" className="modal_form-control mb-2" placeholder="Ener ethereum address or username"></Form.Control>
                            <button type="button" className="btn sitebtn w-100">Track Wallet</button>
                        </Form>
                    </div>
                    <p className="content text-white my-4">
                        Need help connection a wallet ? <Link to="/" className="text-white">Read our FAQ</Link>
                    </p>
                    <p className="content text-white lh-24">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eum ea cum illo praesentium. Dolore sequi, dicta repellat harum dolorum quos, repudiandae iure inventore vitae, voluptatem explicabo! Placeat sunt veniam repellat?
                    </p>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ConnectWalletModal;