import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import './Style.css';
import './MediaStyles.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import Lending from "./Pages/Lending";
import Liquidity from './Pages/Liquidity';
import Multiply from './Pages/Multiply';
import LongShort from './Pages/LongShort';
import Dashboard from './Pages/Dashboard';
import SupplyBorrow from './Pages/SupplyBorrow';
import LiquidityDetail from './Pages/LiquidityDetail';
import MultiplyDetail from './Pages/MultiplyDetail';
import LongShortDetail from './Pages/LongShortDetail';

function App() {
  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Lending />}></Route>
          <Route path="/liquidity" element={<Liquidity />}></Route>
          <Route path="/multiply" element={<Multiply />}></Route>
          <Route path="/long-short" element={<LongShort />}></Route>
          <Route path="/dashboard" element={<Dashboard />}></Route>
          <Route path="/supply-borrow" element={<SupplyBorrow />}></Route>
          <Route path="/liquidity-detail" element={<LiquidityDetail />}></Route>
          <Route path="/multiply-detail" element={<MultiplyDetail />}></Route>
          <Route path="/longshort-detail" element={<LongShortDetail />}></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
