import React, { useState, useEffect } from 'react';
import Header from '../Layouts/Header';
import Footer from '../Layouts/Footer';
import { Container, Placeholder, Row, Col, Dropdown, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { GoArrowLeft } from "react-icons/go";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Images } from '../Components/Images';

const Dashboard = () => {

    useEffect(() => {
        window.scroll({
            left: 0,
            top: 0,
            behavior: "smooth"
        });
    }, []);


    const [dashvalues] = useState([
        {
            valone: "Net value",
            valtwo: "$0.00",
            valthree: "0 SOL"
        },
        {
            valone: "Fees & Interest",
            valtwo: "$0.00",
            valthree: "0 SOL"
        },
        {
            valone: "IWINZ staking",
            valtwo: "0",
            valthree: "30.00% Staking Boost"
        },
        {
            valone: "Season 2 points",
            valtwo: <Placeholder animation="glow"><Placeholder xs={5} /></Placeholder>,
            valthree: <Placeholder animation="glow"><Placeholder xs={5} /></Placeholder>
        },
    ]);

    const [dashtab, setDashtab] = useState(1);


    return (
        <>
            <Header />
            <article className="innerpages">
                <section className="dashboard">
                    <Container>
                        <div className="flex gap-2 mb-2">
                            <div>
                                <Link to="/" className="btn sitebtn backbtn bgbtn"><GoArrowLeft /></Link>
                            </div>
                            <div>
                                <p className="tophead">Dashboard</p>
                            </div>
                        </div>
                        <p className="content">
                            Track all your positions in one place
                        </p>
                        <Row className="align-items-center mt-4">
                            <Col xs={12} sm={12} md={10} lg={10} className="mb-4 mb-md-0">
                                <div className="boxgrid">
                                    {dashvalues.map((dv) => (
                                        <div className="boxgrid_inner">
                                            <p className="boxgrid_head t-light-grey">{dv.valone}</p>
                                            <p className={`boxgrid_value text-white my-1 ${dv.valone.includes("staking") && "text-color"}`}>
                                                {dv.valtwo}
                                            </p>
                                            <p className="boxgrid_head t-light-grey">{dv.valthree}</p>
                                        </div>
                                    ))}
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={2} lg={2}>
                                <div className="positions text-center">
                                    <p className="t-light-grey">Positions</p>
                                    <p className="text-white val mt-2">0</p>
                                </div>
                            </Col>
                        </Row>
                        <div className="dashboard_tab">
                            <div className="flex justify-content-between flex-column flex-md-row gap-3 gap-md-0">
                                <div>
                                    <div className="tabs boxbg p-0">
                                        <button type="button" className={`btn tabbtn ${dashtab === 1 && "bgbtn"}`} onClick={() => setDashtab(1)}>Positions Overview</button>
                                        <button type="button" className={`btn tabbtn ${dashtab === 2 && "bgbtn"}`} onClick={() => setDashtab(2)}>Transaction History</button>
                                    </div>
                                </div>
                                <div>
                                    <div className="flex gap-2">
                                        <div className="boxbg p-0 positionsdrop">
                                            <Dropdown>
                                                <Dropdown.Toggle>
                                                    <span className="t-light-grey">Show:</span> All Positions
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="mt-2">
                                                    <Form>
                                                        <Form.Group className="mb-2">
                                                            <div className="flex gap-2">
                                                                <Form.Check type="checkbox" checked />
                                                                <span className="dropdown-item t-light-grey">All Position</span>
                                                            </div>
                                                        </Form.Group>
                                                        <Dropdown.Divider />
                                                        <Form.Group className="mb-2">
                                                            <div className="flex gap-2">
                                                                <Form.Check type="checkbox" checked />
                                                                <span className="dropdown-item">Borrow / Lend</span>
                                                            </div>
                                                        </Form.Group>
                                                        <Form.Group className="mb-2">
                                                            <div className="flex gap-2">
                                                                <Form.Check type="checkbox" checked />
                                                                <span className="dropdown-item">
                                                                    Liquidity
                                                                </span>
                                                            </div>
                                                        </Form.Group>
                                                        <Form.Group className="mb-2">
                                                            <div className="flex gap-2">
                                                                <Form.Check type="checkbox" checked />
                                                                <span className="dropdown-item">
                                                                    Multiply
                                                                </span>
                                                            </div>
                                                        </Form.Group>
                                                        <Form.Group>
                                                            <div className="flex gap-2">
                                                                <Form.Check type="checkbox" checked />
                                                                <span className="dropdown-item">
                                                                    Long / Short
                                                                </span>
                                                            </div>
                                                        </Form.Group>
                                                    </Form>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="boxbg p-0">
                                            <Dropdown className="dots">
                                                <Dropdown.Toggle>
                                                    <BsThreeDotsVertical />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="mt-2">
                                                    <Dropdown.Item as="button">
                                                        <div className="flex gap-2">
                                                            <img src={Images.buyins} alt="" className="img-fluid" />
                                                            <span className="t-light-grey">Buy Insurance</span>
                                                        </div>
                                                    </Dropdown.Item>
                                                    <Dropdown.Item as="button">
                                                        <div className="flex gap-2">
                                                            <img src={Images.export} alt="" className="img-fluid" />
                                                            <span className="t-light-grey">Export CSV</span>
                                                        </div>
                                                    </Dropdown.Item>
                                                    <Dropdown.Item as="button">
                                                        <div className="flex gap-2">
                                                            <img src={Images.copy} alt="" className="img-fluid" />
                                                            <span className="t-light-grey">Copy</span>
                                                        </div>
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {dashtab === 1 &&
                            <div className="dashboard_tabbox">
                                <img src={Images.dashboardnodata} alt="" className="img-fluid m-auto" width="120" />
                                <div className="my-4">
                                    <p className="content text-white">No open position yet</p>
                                    <p className="content text-white">Connect wallet to see performance of your positions</p>
                                </div>
                                <Link to="/" className="btn sitebtn bgbtn">Explore Products</Link>
                            </div>
                        }
                        {dashtab === 2 &&
                            <div className="dashboard_tabbox">
                                <img src={Images.dashboardnodata} alt="" className="img-fluid m-auto" width="120" />
                                <div className="my-4">
                                    <p className="content text-white">No transactions yet</p>
                                    <p className="content text-white">Connect wallet to see your transactions</p>
                                </div>
                                <Link to="/" className="btn sitebtn bgbtn">Explore Products</Link>
                            </div>
                        }
                    </Container>
                </section>
            </article>
            <Footer />
        </>
    )
}

export default Dashboard;