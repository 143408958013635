import React from 'react';
import { Images } from '../Images';
import { Link } from 'react-router-dom';
import { CiStar } from "react-icons/ci";
import { useNavigate } from 'react-router-dom';

const LongShortTable = (props) => {
    const { thead, table } = props;

    const navigate = useNavigate();
    const handleclick = () => {
        navigate("/longshort-detail");
    }

    return (
        <>
            <div className="table-responsive d-none d-lg-block">
                <table className="table">
                    <thead>
                        <tr>
                            {thead.map((th) => (
                                <th>{th} <img src={Images.theadarrow} alt="" width="10" height="10" className="img-fluid ms-1" /></th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {table.length > 0 ? table.map((td) => (
                            <tr onClick={handleclick}>
                                <td>
                                    <div className="d-flex align-items-center gap-2">
                                        <img src={td.coinimg} alt={td.coinname} className="img-fluid" width="35" height="24" />
                                        <span className="coinname">{td.coinname}</span>
                                        <span className="text-color">
                                            <CiStar />
                                        </span>
                                    </div>
                                </td>
                                <td>
                                    {td.tdone ?
                                        <>
                                            <div className="d-flex align-items-center gap-2">
                                                <span>
                                                    {td.tdone}
                                                </span>
                                            </div>
                                        </> :
                                        <span className="text-center">-</span>
                                    }
                                </td>
                                <td>
                                    {td.tdtwo ?
                                        <>
                                            <span>{td.tdtwo}</span>
                                        </> :
                                        <span className="text-center">-</span>
                                    }
                                </td>
                                <td>
                                    {td.tdthree ?
                                        <span>{td.tdthree}</span> :
                                        <span className="text-center">-</span>
                                    }
                                </td>
                                <td>
                                    {td.tdfour ?
                                        <div className="d-flex align-items-center gap-4">
                                            <div className="d-flex align-items-center gap-2">
                                                <span>{td.tdfour}</span>
                                                <img src={Images.usdc} alt="" width="20" height="20" className="img-fluid" />
                                            </div>
                                            <Link to="/" className="btn sitebtn ms-2">Deposit</Link>
                                        </div> :
                                        <span className="text-center">-</span>
                                    }
                                </td>
                            </tr>
                        )) : 
                        <tr className="nohover">
                            <td colSpan={thead.length} className="text-center py-4">
                                <span className="text-white text-capitalize fnt-15 w-100">no data found</span>
                            </td>
                        </tr>
                        }
                    </tbody>
                </table>
            </div>
            <div className="tablediv d-lg-none">
                <div className="tablediv_inner">
                    {table.length > 0 ? table.map((td) => (
                        <div className="tablediv_innerboxtop" onClick={handleclick}>
                            <div>
                                <div className="d-flex align-items-center gap-2">
                                    <img src={td.coinimg} alt={td.coinname} className="img-fluid" width="45" height="24" />
                                    <span className="coinname">{td.coinname}</span>
                                    <span className="text-color">
                                        <CiStar />
                                    </span>
                                </div>
                            </div>
                            <div className="tablediv_innerchildbox">
                                <div>
                                    {thead[1] && <div className="tablediv_head">{thead[1]}</div>}
                                    {td.tdone ?
                                        <>
                                            <div className="d-flex align-items-center gap-2">
                                                <span>
                                                    {td.tdone}
                                                </span>
                                            </div>
                                        </> :
                                        <span className="text-center">-</span>
                                    }
                                </div>
                                <div>
                                    {thead[2] && <div className="tablediv_head">{thead[2]}</div>}
                                    {td.tdtwo ?
                                        <div>
                                            <span>{td.tdtwo}</span>
                                        </div> :
                                        <span className="text-center">-</span>
                                    }
                                </div>
                                <div>
                                    {thead[3] && <div className="tablediv_head">{thead[3]}</div>}
                                    {td.tdthree ?
                                        <div>
                                            <span>{td.tdthree}</span>
                                        </div> :
                                        <span className="text-center">-</span>
                                    }
                                </div>
                                <div>
                                    {thead[4] && <div className="tablediv_head">{thead[4]}</div>}
                                    {td.tdfour ?
                                        <div className="d-flex align-items-center gap-2">
                                            <span>{td.tdfour}</span>
                                            <img src={Images.usdc} alt="" width="20" height="20" className="img-fluid" />
                                        </div> :
                                        <span className="text-center">-</span>
                                    }
                                </div>
                            </div>
                            <div>
                                <Link to="/" className="btn sitebtn ms-lg-2 w-100">Deposit</Link>
                            </div>
                        </div>
                    )) :
                    <div className="tablediv_innerboxtop mb-4">
                         <span className="text-white text-capitalize fnt-15 w-100 text-center">no data found</span>
                    </div>
                    }
                </div>
            </div>
        </>
    )
}

export default LongShortTable;