import React, { useEffect, useState } from 'react';
import Header from '../Layouts/Header';
import Footer from '../Layouts/Footer';
import { Container, InputGroup, Form, Row, Col } from 'react-bootstrap';
import { Images } from '../Components/Images';
import LendingTable from '../Components/Tables/LendingTable';
import BannerDropdown from '../Components/BannerDropdown';

const Lending = () => {

    // const [dropdown] = useState([
    //     {
    //         drophead: "Borrow / Lead",
    //         dropdesc: "Supply assets to earn yield and borrow against collateral",
    //         url: "/"
    //     },
    //     {
    //         drophead: "Liquidity",
    //         dropdesc: "Earn automated fees & rewards by deploying assets into concentrated liquidity DEXs",
    //         url: "/liquidity"
    //     },
    //     {
    //         drophead: "Multiply",
    //         dropdesc: "Boost your SOL staking yield up to 10x with a single click",
    //         url: "/multiply"
    //     },
    //     {
    //         drophead: "Long / short",
    //         dropdesc: "Increase your exposure to any crypto asset with one-click leverage",
    //         url: "/long-short"
    //     },
    // ]);

    // const location = useLocation();
    // const gridboxinner = [0,1,2,3,4,5];

    const thead = ["Asset", "Total Supply", "Total Borrow", "Max LTV", "Supply APY", "Borrow APY"];
    const [table] = useState([
        {
            coinimg: Images.usdc,
            coinname: "usdc",
            totsupply: "85.5m",
            totborrow: "76.71m",
            maxltv: "80%",
            supply: "12.22%",
            borrow: "17.44%"
        },
        {
            coinimg: Images.jlp,
            coinname: "jlp",
            totsupply: "117.18M",
            totborrow: "73.32M",
            maxltv: "0%",
            supply: "13.92%",
            borrow: "29.73%"
        },
        {
            coinimg: Images.sol,
            coinname: "sol",
            totsupply: "2.82M",
            maxltv: "65%",
            borrow: "6.28%"
        },
        {
            coinimg: Images.pyusd,
            coinname: "PYUSD",
            totsupply: "2.82M",
            totborrow: "2.41M",
            maxltv: "65%",
            supply: "4.52%",
            borrow: "6.28%"
        },
        {
            coinimg: Images.wbtc,
            coinname: "WBTC",
            totsupply: "2.82M",
            totborrow: "2.41M",
            maxltv: "65%",
            supply: "4.52%",
            borrow: "6.28%"
        },
        {
            coinimg: Images.jitosol,
            coinname: "jitosol",
            totsupply: "2.82M",
            totborrow: "2.41M",
            maxltv: "65%",
            supply: "4.52%",
            borrow: "6.28%"
        },
        {
            coinimg: Images.tbtc,
            coinname: "tbtc",
            totsupply: "2.82M",
            totborrow: "2.41M",
            maxltv: "65%",
            supply: "4.52%",
            borrow: "6.28%"
        },
        {
            coinimg: Images.usdc,
            coinname: "usdc",
            totsupply: "85.5m",
            totborrow: "76.71m",
            maxltv: "80%",
            supply: "12.22%",
            borrow: "17.44%"
        },
        {
            coinimg: Images.jlp,
            coinname: "jlp",
            totsupply: "117.18M",
            totborrow: "73.32M",
            maxltv: "0%",
            supply: "13.92%",
            borrow: "29.73%"
        },
        {
            coinimg: Images.sol,
            coinname: "sol",
            totsupply: "2.82M",
            maxltv: "65%",
            borrow: "6.28%"
        },
    ]);

    useEffect(() => {
        window.scroll({
            left: 0,
            top: 0,
            behavior: "smooth"
        });
    }, []);


    const [changeinput, setChangeinput] = useState("");

    const handlechange = (e) => {
        let lowercase = e.target.value.toLowerCase();
        setChangeinput(lowercase);
    }

    const filtered = table.filter((tb) => {
        if (changeinput === "") {
            return tb;
        } else {
            return tb.coinname.toLowerCase().includes(changeinput);
        }
    });

    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleresize = () => {
            setWidth(window.innerWidth);
        }

        window.addEventListener("resize", handleresize);

        return () => {
            window.removeEventListener("resize", handleresize);
        }
    }, [width]);



    return (
        <>
            <Header />
            <article className="lending">
                <section className="bannersection homebanner">
                    <Container>
                        {/* <div className="dropoptions">
                            <Dropdown>
                                <Dropdown.Toggle>
                                    Borrow / Lend
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {dropdown.map((dd) => (
                                        <Dropdown.Item href={dd.url} className={ location.pathname === dd.url && "active" }>
                                            <p className="dropitemhead">{dd.drophead}</p>
                                            <p className="content">{dd.dropdesc}</p>
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                            <p className="content bannercontent">
                                Supply assets to earn yield and borrow against collateral. <span className="text-color">How it works</span>
                            </p>
                        </div> */}
                        <BannerDropdown />
                        <p className="content bannercontent text-center">
                            Supply assets to earn yield and borrow against collateral. <span className="text-color">How it works</span>
                        </p>
                        {width > 991 ?
                            <div className="gridbox mt-5 d-none d-lg-grid">
                                <div className="gridbox_div">
                                    <div className="gridbox_divinner">
                                        <img src={Images.borrowone} alt="" className="img-fluid borrowimg" />
                                        <img src={Images.borrowonepair} alt="" className="img-fluid borrowpairimg" />
                                    </div>
                                    <p className="content bannerimgscontent">
                                        Supply assets to earn yield and to use as collateral
                                    </p>
                                </div>
                                <div className="gridbox_div">
                                    <div className="gridbox_divinner">
                                        <img src={Images.borrowtwo} alt="" className="img-fluid borrowimg" />
                                        <img src={Images.borrowtwopair} alt="" className="img-fluid borrowpairimg borrowpairimgtwo" />
                                    </div>
                                    <p className="content bannerimgscontent">
                                        Borrow assets against your deposited collateral
                                    </p>
                                </div>
                                <div className="gridbox_div">
                                    <div className="gridbox_divinner gridbox_divinnertwo">
                                        <img src={Images.borrowthree} alt="" className="img-fluid borrowimg" />
                                    </div>
                                    <p className="content bannerimgscontent">
                                        Borrow up to Maximum loan-to-value (LTV) ratio, which is calculated using your specific Collateral x Debt combination
                                    </p>
                                </div>
                                <div className="gridbox_div">
                                    <div className="gridbox_divinner gridbox_divinnertwo">
                                        <img src={Images.borrowfour} alt="" className="img-fluid borrowimg" />
                                        <img src={Images.borrowfourpair} alt="" className="img-fluid borrowpairimg borrowpairimgfour" />
                                    </div>
                                    <p className="content bannerimgscontent">
                                        If you reach Liquidation LTV (&gt;Max LTV), you can lose your collateral via liquidation - while retaining borrowed assets
                                    </p>
                                </div>
                            </div> :
                            <Row className="mt-5 d-lg-none">
                                <Col xs={12} md={6} className="mb-5">
                                    <div className="flexbox_div mh-auto one">
                                        <img src={Images.lendingone} alt="" className="img-fluid bannerimgs" />
                                        <p className="content bannerimgscontent">
                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos temporibus, odio
                                        </p>
                                    </div>
                                </Col>
                                <Col xs={12} md={6} className="mb-5">
                                    <div>
                                        <img src={Images.lendingtwo} alt="" className="img-fluid bannerimgs" />
                                        <p className="content bannerimgscontent">
                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos temporibus, odio
                                        </p>
                                    </div>
                                </Col>
                                <Col xs={12} md={6} className="mb-5 mb-md-0">
                                    <div className="flexbox_div mh-auto">
                                        <img src={Images.lendingthree} alt="" className="img-fluid bannerimgs" />
                                        <p className="content bannerimgscontent">
                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos temporibus, odio
                                        </p>
                                    </div>
                                </Col>
                                <Col xs={12} md={6}>
                                    <div className="flexbox_div mh-auto">
                                        <img src={Images.lendingfour} alt="" className="img-fluid bannerimgs" width="100" />
                                        <p className="content bannerimgscontent">
                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos temporibus, odio
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        }
                    </Container>
                    <div className="bannerbottom"></div>
                </section>
                <section className="tablesection">
                    <Container>
                        <Form>
                            <InputGroup>
                                <InputGroup.Text>
                                    <img src={Images.search} alt="Search" className="img-fluid" width="20" height="20" />
                                </InputGroup.Text>
                                <Form.Control type="text" placeholder="Search..." onChange={handlechange}></Form.Control>
                            </InputGroup>
                        </Form>
                        <div className="d-flex align-items-center gap-3 my-5">
                            <img src={Images.mainmarket} alt="" className="img-fluid" width="18" height="18" />
                            <span className="text-white">Main Market</span>
                        </div>
                        <LendingTable thead={thead} table={filtered} />
                        {/* <LendingTable thead={thead} table={searchfunc(table)} /> */}
                        <div className="table_underflex d-flex align-items-center justify-content-center gap-2 gap-sm-3 my-4">
                            <div>
                                <p className="content">Total Supplied <strong>$1.47B</strong></p>
                            </div>
                            <div>
                                <span className="t-grey">|</span>
                            </div>
                            <div>
                                <p className="content">Total Borrowed <strong>$527.66M</strong></p>
                            </div>
                            <div>
                                <span className="t-grey">|</span>
                            </div>
                            <div>
                                <p className="content">Borrow / Lend TVL <strong>$938.12M</strong></p>
                            </div>
                        </div>
                    </Container>
                </section>
            </article>

            <Footer />
        </>
    )
}

export default Lending;