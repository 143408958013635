import React, { useState, useEffect } from 'react';
import Header from '../Layouts/Header';
import Footer from '../Layouts/Footer';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { GoArrowLeft } from "react-icons/go";
import { Images } from '../Components/Images';
import QuizModal from '../Components/Modals/QuizModal';
import MoreInfoModal from '../Components/Modals/MoreInfoModal';
import InfoRiskAccordion from '../Components/InfoRiskAccordion';
import Depositbox from '../Components/DepositBox';

const MultiplyDetail = () => {

    const [show, setShow] = useState(false);
    const [showthree, setShowthree] = useState(false);

    const [tab, setTab] = useState(1);

    const [price] = useState([
        {
            priceone: "JUPSOL Price",
            pricetwo: "$164.76",
        },
        {
            priceone: "Liquidity Available",
            pricetwo: "$164.76"
        },
        {
            priceone: "Max APY",
            pricetwo: "12.98%"
        },
    ]);

    const [supply] = useState([
        {
            valone: "Simulated Deposit",
            valtwo: "100 SOL",
            valimg: Images.sol
        },
        {
            valone: "With Multiplier",
            valtwo: "4.99x",
        },
        {
            valone: "Net APY",
            valtwo: "12.98%",
        },
    ]);

    const multiovertabhead = ["Period", "Estimated Net Earnings", "Estimated Net Value"];

    const [multiovertab] = useState([
        {
            valone: "30 days",
            valtwo: "$162.48",
            valthree: "$16.28K"
        },
        {
            valone: "90 days",
            valtwo: "$492.35",
            valthree: "$16.61K"
        },
        {
            valone: "180 days",
            valtwo: "$999.75",
            valthree: "$17.12K"
        },
        {
            valone: "365 days",
            valtwo: "$2.90K",
            valthree: "$18.21K"
        },
    ]);

    const [vaultstatus] = useState([
        {
            valone: "Total JUPSOL Suppiled",
            valtwo: "1.15M JUPSOL",
            valthree: "$192.12M"
        },
        {
            valone: "Total SOL Borrowed",
            valtwo: "926.21K SOL",
            valthree: "$150.07M"
        },
        {
            valone: "Avg. Multiplier",
            valtwo: "4.23x",
        },
        {
            valone: "JUPSOL APY",
            valtwo: "7.75%",
        },
    ]);


    const [dropcon] = useState([
        {
            dimg: Images.jlp,
            dcoinname: "JUPSOL"
        },
        {
            dimg: Images.sol,
            dcoinname: "SOL"
        },
    ]);

    const [position] = useState([
        {
            valone: "Net Value",
            valtwo: "- SOL",
            valthree: ""
        },
        {
            valone: "PnL",
            valtwo: Images.clock,
        },
        {
            valone: "Net APY",
            valtwo: "",
        },
        {
            valone: "Multiplier",
            valtwo: "",
        },
    ]);

    const [analyticsone] = useState([
        {
            valone: "Total Collateral",
            valtwo: "",
            valthree: ""
        },
        {
            valone: "Total Debt",
            valtwo: "",
            valthree: ""
        },
        {
            valone: "LTV",
            valtwo: ""
        }
    ]);

    const [inforiskacc] = useState([
        {
            acchead: "What does this vault do ?",
            acccontent: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Autem dignissimos dolor praesentium repellat laudantium quasi exercitationem ullam in dolorem"
        },
        {
            acchead: "Where does the yield (APY) come from ?",
            acccontent: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Autem dignissimos dolor praesentium repellat laudantium quasi exercitationem ullam in dolorem"
        },
        {
            acchead: "When is this vault profitable ?",
            acccontent: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Autem dignissimos dolor praesentium repellat laudantium quasi exercitationem ullam in dolorem"
        },
        {
            acchead: "When does this vault perform sub-optimally ?",
            acccontent: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Autem dignissimos dolor praesentium repellat laudantium quasi exercitationem ullam in dolorem"
        },
        {
            acchead: "Is there an insurance fund and what happens when there is bad debt ?",
            acccontent: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Autem dignissimos dolor praesentium repellat laudantium quasi exercitationem ullam in dolorem"
        },
    ]);

    const [multiplyinfo] = useState([
        {
            valone: "Max LTV",
            valtwo: "65.00%"
        },
        {
            valone: "SOL Borrow Rate",
            valtwo: "6.43%"
        },
        {
            valone: "Available Liquidity",
            valtwo: "$50.25M"
        },
    ]);


    const MIN = 1.0;
    const MAX = 5.0;

    const marks = [
        {
            value: MIN,
            label: `${MIN}x`,
        },
        {
            value: MAX,
            label: `${MAX}x`,
        },
    ];

    const [val, setVal] = useState(MIN);

    const handleChange = (_, newValue) => {
        setVal(newValue);
    };


    const [resize, setResize] = useState(window.innerWidth);

    useEffect(() => {
        const handleresize = () => {
            setResize(window.innerWidth)
        }

        window.addEventListener("resize", handleresize);

        return () => {
            window.removeEventListener("resize", handleresize);
        }
    }, [resize]);

    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }, []);

    const [tabbtn] = useState([
        {
            val: "Overview"
        },
        {
            val: "My Position"
        },
        {
            val: "Info & Risk"
        },
    ]);



    return (
        <>
            <Header />

            <article className="innerpages">
                <section className="multiplydetail">
                    <Container>
                        <div className="d-block d-lg-flex flex justify-content-between gap-2">
                            <div className="flex gap-2 mb-4 mb-lg-0">
                                <Link to="/liquidity" className="btn sitebtn backbtn bgbtn"><GoArrowLeft /></Link>
                                <img src={Images.jlp} alt="" className="img-fluid topheadimg" />
                                <p className="tophead">JUPSOL Multiply</p>
                            </div>
                            <div className="boxbg rightboxbg">
                                <div className="flex gridb">
                                    {price.map((pr) => (
                                        <>
                                            <p className={`content t-light-grey ${pr.priceone.toLowerCase().includes("dex") && "align-items-sm-center gap-2"}`}>
                                                <span className="t-light-grey text-capitalize">
                                                    {pr.priceone}
                                                </span>
                                                <span className={`text-white ${pr.priceone.toLowerCase().includes("dex") ? "" : "ms-sm-2"}`}>{pr.pricetwo}</span>
                                            </p>
                                            {pr.pricetwo && <span className="t-light-grey mx-2">|</span>}
                                        </>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="d-md-flex align-items-sm-center justify-content-sm-between mt-4 mt-sm-5 sm-flex">
                            <div className="tabs boxbg p-0 mb-4 mb-md-0">
                                {tabbtn.map((tb, i) => (
                                    <button type="button" className={`btn tabbtn ${tab === (i + 1) && "bgbtn"}`} onClick={() => setTab((i + 1))}>{tb.val}</button>
                                ))}
                                {/* <button type="button" className={`btn tabbtn ${tab === 1 && "bgbtn"}`} onClick={() => setTab(1)}>Overview</button>
                                <button type="button" className={`btn tabbtn ${tab === 2 && "bgbtn"}`} onClick={() => setTab(2)}>My Position</button>
                                <button type="button" className={`btn tabbtn ${tab === 3 && "bgbtn"}`} onClick={() => setTab(3)}>Info & Risk</button> */}
                            </div>
                            <div>
                                <div className="flex align-items-center justify-content-between justify-content-md-start gap-4">
                                    <div className="d-flex align-items-center gap-1">
                                        <span className="text-color cirques fnt-13">?</span>
                                        <span className="text-color fnt-15">How Multiply Works</span>
                                    </div>
                                    <button type="button" className="btn boxbg text-white text-capitalize" onClick={() => setShow(true)}>
                                        <img src={Images.quiz} alt="" className="img-fluid" width="18" />
                                        <span className="ms-2">quiz</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <Row className="mt-4 f-column">
                            <Col xs={12} sm={12} md={12} lg={8} className="multiplyoverview">
                                {tab === 1 &&
                                    <>
                                        <div className="innerbg mb-3 p-3 topbox">
                                            <Row>
                                                {supply.map((sy) => (
                                                    <Col xs={6} sm={4} lg={4}>
                                                        <div className="innerbox transinner">
                                                            <span className="mb-2 t-light-grey text-capitalize">
                                                                {sy.valone}
                                                                {sy.valone.toLowerCase().includes("net") &&
                                                                    <span className="cirques fnt-13">?</span>}
                                                            </span>
                                                            <span className="text-white fnt-16">
                                                                {sy.valone.toLowerCase().includes("deposit") &&
                                                                    <img src={sy.valimg} alt="" className="img-fluid" width="20" />}
                                                                {sy.valtwo}
                                                            </span>
                                                        </div>
                                                    </Col>
                                                ))}
                                            </Row>
                                            <hr className="bt-line" />
                                            <div className="multiovertab">
                                                <div className="multiovertabhead gridbox mb-3">
                                                    {multiovertabhead.map((mth) => (
                                                        <span className={`fnt-14 ${mth.toLowerCase().includes("estimated") && "bt-line"}`}>{mth}</span>
                                                    ))}
                                                </div>
                                                <div className="multiovertabbody">
                                                    {multiovertab.map((mb) => (
                                                        <div className="gridbox">
                                                            {resize < 576 && <span className="t-light-grey fnt-14">{multiovertabhead[0]}</span>}
                                                            <span className="text-white fnt-14">{mb.valone}</span>
                                                            {resize < 576 && <span className="t-light-grey fnt-14">{multiovertabhead[1]}</span>}
                                                            <span className="text-white fnt-14">{mb.valtwo}</span>
                                                            {resize < 576 && <span className="t-light-grey fnt-14">{multiovertabhead[2]}</span>}
                                                            <span className="text-white fnt-14">{mb.valthree}</span>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="innerbg mb-3 p-3 positionboxes">
                                            <div className="innerbgdivrow">
                                                <h5 class="h5 text-white mb-3 fw-400">Vault Stats</h5>
                                                <div className="gridbox">
                                                    {vaultstatus.map((sy) => (
                                                        <div>
                                                            <div className="innerbox">
                                                                <span className="mb-2 t-light-grey text-capitalize">
                                                                    {sy.valone}
                                                                    {sy.valone.toLowerCase().includes("apy") && <span className="cirques fnt-13">?</span>}
                                                                </span>
                                                                <span className="mt-2 text-white fnt-16">
                                                                    {sy.valtwo}
                                                                </span>
                                                                {sy.valthree && <span className="t-light-grey mt-2">{sy.valthree}</span>}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                                {tab === 2 &&
                                    <>
                                        <div className="innerbg mb-3 p-3 positionboxes">
                                            <div className="innerbgdivrow">
                                                <div className="gridbox">
                                                    {position.map((sy) => (
                                                        <div>
                                                            <div className="innerbox m-auto">
                                                                <span className="mb-2 t-light-grey text-capitalize">
                                                                    {sy.valone}
                                                                </span>
                                                                <span className="mt-2 text-white fnt-16">
                                                                    {sy.valtwo === "" && <span className="fnt-13">-</span>}
                                                                    {sy.valone.toLowerCase().includes("pnl") ? <img src={sy.valtwo} alt="" className="img-fluid" width="20" /> : sy.valtwo}
                                                                </span>
                                                                {sy.valthree === "" && <span className="t-light-grey mt-1">-</span>}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="innerbg mb-3 p-3 analyticsone">
                                            <div className={`gridbox gridtempthree ${analyticsone.length > 3 ? "gridb" : "gap-4 gap-md-5"}`}>
                                                {analyticsone.map((sy) => (
                                                    <div className="innerbox">
                                                        <span className="mb-2 t-light-grey text-capitalize">
                                                            {sy.valone}
                                                        </span>
                                                        <span className={`${sy.valone.toLowerCase().includes("ltv") && "text-color"} text-white fnt-16`}>
                                                            {sy.valtwo ? sy.valtwo : "-"}
                                                        </span>
                                                        <span className="t-light-grey">
                                                            {sy.valthree === "" && "-"}
                                                        </span>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </>
                                }
                                {tab === 3 &&
                                    <>
                                        <InfoRiskAccordion data={inforiskacc} />
                                    </>
                                }
                                <div className="innerbg p-3 mt-3">
                                    <div className="innerbgdiv">
                                        <Row className="align-items-center">
                                            <Col xs={12} sm={12} md={9} lg={9} className="mb-4 mb-md-0">
                                                <h5 className="h5 conhead text-white fw-400">
                                                    Can I Get Liquidated ?
                                                </h5>
                                                <p className="content text-white my-3 lh-24">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vel viverra lorem. Etiam efficitur aliquet erat vel convallis. Aliquam sed ipsum dolor. Nam molestie lectus iaculis ornare vulputate. Nulla facilisi. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                </p>
                                                <button type="button" className="btn sitebtn bgbtn" onClick={() => setShowthree(true)}>More Info</button>
                                            </Col>
                                            <Col xs={12} sm={12} md={3} lg={3} className="text-center">
                                                <img src={Images.searchimg} alt="" className="img-fluid conimg" />
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                            {(tab === 1 || tab === 2) &&
                                <Col xs={12} sm={12} md={12} lg={4} className="mb-4 mb-lg-0">
                                    <Depositbox dropcon={dropcon} marks={marks} min={MIN} max={MAX} val={val} handleChange={handleChange} mediumvalue="0.02%" rangename="Multiply" tab={tab} />
                                </Col>
                            }
                            {tab === 3 &&
                                <Col xs={12} md={12} lg={4} className="mb-4 mb-lg-0">
                                    <div className="innerbg p-3">
                                        <p className="mb-0 mb-3 fnt-15 text-white text-capitalize">Multiply Info</p>
                                        <div className="supplydet mt-2 inforisk_det">
                                            {multiplyinfo.map((dt) => (
                                                <div className="flex justify-content-between">
                                                    <span className="t-light-grey fnt-14">{dt.valone}</span>
                                                    <span className="text-white fnt-14">
                                                        {dt.valtwo}
                                                    </span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </Col>
                            }
                        </Row>
                    </Container>
                </section>
            </article>

            <Footer />

            <QuizModal show={show} close={() => setShow(false)} />
            <MoreInfoModal show={showthree} close={() => setShowthree(false)} />
        </>
    )
}

export default MultiplyDetail;