import React, { useEffect, useState } from 'react';
import Header from '../Layouts/Header';
import Footer from '../Layouts/Footer';
import { Container, Badge, Row, Col, Dropdown, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { GoArrowLeft } from "react-icons/go";
import { Images } from '../Components/Images';
import QuizModal from '../Components/Modals/QuizModal';
import ReactApexChart from "react-apexcharts";
import ApexCharts from 'apexcharts';
// import ConnectWalletModal from '../Components/Modals/ConnectWalletModal';
// import Settings from '../Components/Modals/SettingsModal';
// import DepositModal from '../Components/Modals/DepositModal';
import InfoRiskAccordion from '../Components/InfoRiskAccordion';
import Depositbox from '../Components/DepositBox';

const SupplyBorrow = () => {

    const [price] = useState([
        {
            priceone: "oracle Price",
            pricetwo: "$1.00"
        },
        {
            priceone: "supply APY",
            pricetwo: "10.73%"
        },
        {
            priceone: "borrow APY",
            pricetwo: "24.54%"
        },
        {
            priceone: "oracle"
        },
    ]);

    const [tab, setTab] = useState(1);
    const [show, setShow] = useState(false);

    const [coindet] = useState([
        {
            coinhead: "USDC Supplied",
            coinvalue: "128.30M USDC"
        },
        {
            coinhead: "Liquidity Available",
            coinvalue: "54.49M USDC"
        },
        {
            coinhead: "Utilization",
            coinvalue: "58.07%"
        },
    ]);

    const [supply] = useState([
        {
            valone: "total supplied",
            valtwo: "127.87M",
            valthree: "$127.87M"
        },
        {
            valone: "supply APY",
            valtwo: "10.89%",
            valthree: "11.95% 30d avg"
        },
        {
            valone: "supply cap",
            valtwo: "200.00M",
            valthree: "$200.00M"
        },
        {
            valone: "Asset Tier",
            valtwo: "Regular"
        }
    ]);

    const [borrow] = useState([
        {
            valone: "total borrowed",
            valtwo: "74.78M",
            valthree: "$74.78M"
        },
        {
            valone: "borrow APY",
            valtwo: "24.73%",
            valthree: "27.44% 30d avg"
        },
        {
            valone: "borrow cap",
            valtwo: "112.00M",
            valthree: "$112.00M"
        },
        {
            valone: "Borrow Factor",
            valtwo: "1.00",
            valthree: <Link to="/" className="text-color text-decoration-none">What’s this?</Link>,
        }
    ]);

    const [liquidation] = useState([
        {
            valone: "Max LTV",
            valtwo: "0.00%"
        },
        {
            valone: "Liquidation LTV",
            valtwo: "0.00%"
        },
        {
            valone: "Liquidation Penalty",
            valtwo: "5.00% - 10.00%"
        }
    ]);

    // const [chartoption] = useState({
    //     options: {
    //         chart: {
    //             type: "line",
    //             stacked: false
    //         },
    //         dataLabels: {
    //             enabled: false
    //         },
    //         title: {
    //             text: "Supply & Borrow",
    //             align: "left",
    //             style: {
    //                 color: "#FFF",
    //                 fontSize: "18px",
    //                 fontWeight: 500
    //             }
    //         },
    //         colors: ['#49AFE9', '#66DA26', '#FF0004', '#E91E63'],
    //         series: [
    //             {
    //                 name: "Supply TVL",
    //                 data: [12.4, 23, 52.5, 31.5, 82.5, 22.8, 73.8, 148.6]
    //             },
    //             {
    //                 name: "Supply APY",
    //                 data: [20, 29, 37, 36, 44, 45, 50, 98]
    //             },
    //             {
    //                 name: "Borrow TVL",
    //                 data: [123.4, 20, 112.5, 75, 112.5, 76, 93.8, 123]
    //             },
    //             {
    //                 name: "Borrow APY",
    //                 data: [20, 123.4, 37, 36, 72.5, 65, 90, 78]
    //             }
    //         ],
    //         stroke: {
    //             curve: "smooth",
    //             width: [1, 1]
    //         },
    //         plotOptions: {
    //             bar: {
    //                 columnWidth: "20%"
    //             }
    //         },
    //         xaxis: {
    //             type: 'datetime',
    //             // categories: [2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016],
    //             min: new Date('01 Jun 2024').toLocaleString(),
    //             max: new Date('01 Aug 2024').toLocaleString(),
    //             tickAmount: 6,
    //             format: 'dd / MM',
    //             labels: {
    //                 show: true,
    //                 style: {
    //                     colors: "#FFFFFF"
    //                 },
    //                 formatter: function (value) {
    //                     return value;
    //                   }
    //             },

    //             axisBorder: {
    //                 show: true,
    //                 color: '#FFFFFF1A',
    //                 height: 1,
    //                 width: '100%',
    //             },
    //             axisTicks: {
    //                 show: false
    //             }
    //         },
    //         yaxis: [
    //             {
    //                 axisTicks: {
    //                     show: true,
    //                     borderType: 'solid',
    //                     width: 0,
    //                 },
    //                 axisBorder: {
    //                     show: false,
    //                     color: "#FFFFFF"
    //                 },
    //                 labels: {
    //                     style: {
    //                         colors: "#fff",
    //                         fontFamily: '"Roboto", sans-serif',
    //                         fontWeight: 400,
    //                         fontSize: "12px"
    //                     },
    //                     formatter: function(val, index) {
    //                         return `${val.toFixed(2)}M`;
    //                     }
    //                 }
    //             },
    //             {
    //                 opposite: true,
    //                 axisTicks: {
    //                     show: true
    //                 },
    //                 axisBorder: {
    //                     show: false,
    //                     color: "#247BA0"
    //                 },
    //                 labels: {
    //                     style: {
    //                         colors: "#fff",
    //                         fontFamily: '"Roboto", sans-serif',
    //                         fontWeight: 400,
    //                         fontSize: "12px"
    //                     },
    //                     formatter: function(val, index) {
    //                         return `${val.toFixed(2)}%`;
    //                     }
    //                 }
    //             }
    //         ],
    //         tooltip: {
    //             shared: false,
    //             intersect: true,
    //             x: {
    //                 show: false
    //             }
    //         },
    //         legend: {
    //             show: true,
    //             horizontalAlign: "center",
    //             offsetX: 40,
    //             labels: {
    //                 colors: ["#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"]
    //             }
    //         }
    //     }
    // });

    const [chartoption] = useState({
        series: [
            {
                name: "Supply TVL",
                data: [
                    [new Date('03 Mar 2024').getTime(), 60.95],
                    [new Date('06 Mar 2024').getTime(), 61.34],
                    [new Date('10 Mar 2024').getTime(), 47.94],
                    [new Date('01 Apr 2024').getTime(), 38.14],
                    [new Date('01 Jul 2024').getTime(), 18.66],
                ],
                color: "#49AFE9"
            },
            {
                name: "Supply APY",
                data: [
                    [new Date('03 Mar 2024').getTime(), 62.61],
                    [new Date('06 Mar 2024').getTime(), 62.52],
                    [new Date('10 Apr 2024').getTime(), 72.67],
                    [new Date('01 May 2024').getTime(), 82.52],
                    [new Date('01 Jun 2024').getTime(), 91.92],
                    [new Date('04 Jul 2024').getTime(), 92.20],
                    [new Date('08 Aug 2024').getTime(), 102.23],
                ],
                color: "#66DA26"
            },
            {
                name: "Borrow TVL",
                data: [
                    [new Date('12 Apr 2024').getTime(), 100.43],
                    [new Date('22 Mar 2024').getTime(), 104.44],
                    [new Date('11 May 2024').getTime(), 110.20],
                    [new Date('30 Jun 2024').getTime(), 120.14],
                    [new Date('21 Jul 2024').getTime(), 130.65],
                ],
                color: "#FF0004"
            },
            {
                name: "Borrow APY",
                data: [

                    [new Date('03 Apr 2024').getTime(), 173.71],
                    [new Date('12 Apr 2024').getTime(), 93.81],
                    [new Date('30 Apr 2024').getTime(), 134.40],
                    [new Date('03 May 2024').getTime(), 140.63],
                    [new Date('20 May 2024').getTime(), 100.46],
                    [new Date('15 Jun 2024').getTime(), 112.48],
                    [new Date('22 Jul 2024').getTime(), 127.31],
                ],
                color: "#E91E63"
            }

        ],

        options: {
            chart: {
                type: 'line',
                id: "mychart",
                toolbar: {
                    show: false
                }
            },
            plotOptions: {
                line: {
                    horizontal: true
                }
            },
            dataLabels: {
                enabled: false,

            },
            title: {
                text: "Supply & Borrow",
                align: "left",
                style: {
                    color: "#FFF",
                    fontSize: "18px",
                    fontWeight: 500
                },
                margin: 20
            },

            xaxis: {
                showAlways: true,
                type: 'datetime',
                min: new Date('01 Mar 2024').getTime(),
                max: new Date('01 Aug 2024').getTime(),
                tickAmount: 6,
                labels: {
                    style: {
                        colors: "#fff",
                        fontFamily: '"Roboto", sans-serif',
                        fontWeight: 400,
                        fontSize: "12px"
                    },
                    format: "dd/MM",
                },
                axisBorder: {
                    show: true,
                    color: '#FFFFFF1A',
                    height: 1,
                    width: '100%'
                },
                axisTicks: {
                    show: false
                },

            },
            yaxis: [
                {
                    showAlways: true,
                    axisTicks: {
                        show: true,
                        borderType: 'solid',
                        width: 0,
                    },
                    axisBorder: {
                        show: false,
                        color: "#FFFFFF"
                    },
                    labels: {
                        style: {
                            colors: "#fff",
                            fontFamily: '"Roboto", sans-serif',
                            fontWeight: 400,
                            fontSize: "12px"
                        },
                        formatter: function (val, index) {
                            return `${val.toFixed(2)}M`;
                        },
                    }
                },
                {
                    showAlways: true,
                    opposite: true,
                    axisTicks: {
                        show: true
                    },
                    axisBorder: {
                        show: false,
                        color: "#247BA0"
                    },
                    labels: {
                        style: {
                            colors: "#fff",
                            fontFamily: '"Roboto", sans-serif',
                            fontWeight: 400,
                            fontSize: "12px"
                        },
                        formatter: function (val, index) {
                            return `${val.toFixed(2)}%`;
                        }
                    }
                }
            ],
            grid: {
                borderColor: '#FFFFFF1A',
                padding: {
                    left: 30,
                    right: 30
                },
            },
            tooltip: {
                x: {
                    format: 'dd MMM yyyy'
                }
            },
            stroke: {
                curve: "smooth",
                width: [1, 1]
            },
            legend: {
                show: false,
                horizontalAlign: "left",
                offsetX: 100,
                labels: {
                    colors: ["#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"]
                },
                itemMargin: {
                    horizontal: 10,
                    vertical: 30
                },
                markers: {
                    size: 10,
                    shape: 'square',
                    strokeWidth: 1,
                    fillColors: ['#49AFE9', '#66DA26', '#FF0004', '#E91E63'],
                    customHTML: function () {
                        return '<input type="checkbox" class="form-control me-2" checked />'
                    },

                },

            },
            // responsive: [
            //     {
            //         breakpoint: 768,
            //         options: {
            //             plotOptions: {
            //                 bar: {
            //                   horizontal: false
            //                 }
            //             },
            //             legend: {
            //                 position: "top",
            //                 offsetX: 0
            //             },
            //             grid: {
            //                 padding: {
            //                     left: 15,
            //                     right: 15
            //                 },
            //             },
            //         }
            //     }
            // ]
        },
    });

    const [chartcheck] = useState([
        {
            value: "Supply TVL",
            valuedate: "7D"
        },
        {
            value: "Supply APY",
            valuedate: "30D"
        },
        {
            value: "Borrow TVL",
            valuedate: "90D"
        },
        {
            value: "Borrow APY",
            valuedate: "6M"
        },
    ]);

    const [chartdrop, setChartdrop] = useState("30D");

    const updateData = (timeline) => {
        setChartdrop(timeline);
        switch (timeline) {
            case '7D':
                ApexCharts.exec('mychart', 'updateOptions', {
                    xaxis: {
                        min: new Date("01 Mar 2024").getTime(),
                        max: new Date("08 Mar 2024").getTime()
                    }
                }, false, true);
                break;
            case '30D':
                ApexCharts.exec('mychart', 'updateOptions', {
                    xaxis: {
                        min: new Date("01 Mar 2024").getTime(),
                        max: new Date("31 Mar 2024").getTime()
                    }
                }, false, true);
                break;
            case '90D':
                ApexCharts.exec('mychart', 'updateOptions', {
                    xaxis: {
                        min: new Date("01 Mar 2024").getTime(),
                        max: new Date("01 Jun 2024").getTime()
                    }
                }, false, true);
                break;
            case '6M':
                ApexCharts.exec('mychart', 'updateOptions', {
                    xaxis: {
                        min: new Date("01 Mar 2024").getTime(),
                        max: new Date("01 Sep 2024").getTime()
                    }
                }, false, true);
                break;
            default:
                ApexCharts.exec('mychart', 'updateOptions', {
                    xaxis: {
                        min: new Date("01 Mar 2024").getTime(),
                        max: new Date("31 Mar 2024").getTime()
                    }
                }, false, true);
        }
    }

    // ApexCharts.exec('mychart', 'updateOptions', {
    //     "7d": {
    //         xaxis: {
    //             min: new Date("01 Mar 2024").getTime(),
    //             max: new Date("08 Mar 2024").getTime()
    //         }
    //     },
    //     "30d": {
    //         xaxis: {
    //             min: new Date("01 Mar 2024").getTime(),
    //             max: new Date("31 Mar 2024").getTime()
    //         }
    //     },
    //     "90d": {
    //         xaxis: {
    //             min: new Date("01 Mar 2024").getTime(),
    //             max: new Date("01 Jun 2024").getTime()
    //         }
    //     },
    //     "6m": {
    //         xaxis: {
    //             min: new Date("01 Mar 2024").getTime(),
    //             max: new Date("01 Sep 2024").getTime()
    //         }
    //     },
    //     all: {
    //         xaxis: {
    //             min: undefined,
    //             max: undefined
    //         }
    //     }
    // }, false, true);

    const [chartoptiontwo] = useState({
        series: [{
            name: "Borrow APY",
            data: [10, 41, 35, 51, 49, 52, 69, 95],
            color: "#FFD37FE5"
        },
        {
            name: "Supply APY",
            data: [10, 51, 35, 21, 49, 52, 69, 90],
            color: "#1CBFDAE5"
        }
        ],
        options: {
            chart: {
                height: 350,
                type: 'line',
                toolbar: {
                    show: false
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'straight',
                width: [1, 1]
            },
            title: {
                text: "Interest Rate and Utilization",
                align: "left",
                style: {
                    color: "#FFF",
                    fontSize: "18px",
                    fontWeight: 500
                },
                margin: 20
            },
            xaxis: {
                categories: ["0%", "11%", "22%", "33%", "44%", "55%", "66%", "77%", "88%", "100%"],
                labels: {
                    style: {
                        colors: "#fff",
                        fontFamily: '"Roboto", sans-serif',
                        fontWeight: 400,
                        fontSize: "12px"
                    },
                },
                axisBorder: {
                    show: true,
                    color: '#FFFFFF1A',
                    height: 1,
                    width: '100%'
                },
                axisTicks: {
                    show: false
                },
                formatter: function (val, index) {
                    return `${val}%`;
                },
            },
            yaxis: {
                showAlways: true,
                axisTicks: {
                    show: false,
                },
                axisBorder: {
                    show: false,
                },
                labels: {
                    style: {
                        colors: "#fff",
                        fontFamily: '"Roboto", sans-serif',
                        fontWeight: 400,
                        fontSize: "12px"
                    },
                    formatter: function (val, index) {
                        return `${val}%`;
                    },
                }
            },
            grid: {
                borderColor: '#FFFFFF1A',
                padding: {
                    left: 30,
                    right: 30
                },
            },
            legend: {
                show: false,
                labels: {
                    colors: ["#FFFFFF", "#FFFFFF"]
                },
                horizontalAlign: "left",
                offsetX: 30,
                offsetY: 10,
                itemMargin: {
                    horizontal: 10,
                },
                // markers: {
                //     size: 10,
                //     shape: 'square',
                //     strokeWidth: 0,
                //     customHTML: function () {
                //         return `<img src=${Images.supplyicon} alt="" class="img-fluid" />`
                //     },

                // },
            },
            // subtitle: {
            //     text: "Current Utilization: 58.31%",
            //     style: {
            //         color: "#fff",
            //         fontFamily: '"Roboto", sans-serif',
            //         fontWeight: 400,
            //         fontSize: "13px"
            //     },
            //     floating: true,
            //     offsetY: 473,
            //     offsetX: 290
            // },
            // responsive: [
            //     {
            //         breakpoint: 600,
            //         options: {
            //             legend: {
            //                 // position: "top",
            //                 offsetX: 0,
            //             },
            //         },
            //         subtitle: {
            //             offsetX: 0
            //         },
            //     }
            // ]
        },
    });

    // const [drop, setDrop] = useState("supply");
    // const [showmodal, setShowmodal] = useState(false);
    // const [showtwo, setShowtwo] = useState(false);

    // const [supplyshow, setSupplyshow] = useState(false);
    // const [showbtn, setShowbtn] = useState(false);

    // const handlechange = (e) => {
    //     if (drop === "supply" && e.target.value.length > 0) {
    //         setSupplyshow(true);
    //         setShowbtn(true);
    //     } else {
    //         setSupplyshow(false);
    //         setShowbtn(false);
    //     }
    // }

    const [supplydet] = useState([
        {
            valone: "Deposit value",
            valtwo: "10 USDC"
        },
        {
            valone: "Loan-to-value-ratio",
            valtwo: "0.00% -> 0.00%"
        },
        {
            valone: "Supply APY",
            valtwo: "23.08% -> 23.08%"
        },
        {
            valone: "Utilization Ratio",
            valtwo: "79.40% -> 79.40%"
        },
        {
            valone: "Asset tier",
            valtwo: "Regular"
        },
    ]);

    const [inforiskacc] = useState([
        {
            acchead: "What does this vault do ?",
            acccontent: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Autem dignissimos dolor praesentium repellat laudantium quasi exercitationem ullam in dolorem"
        },
        {
            acchead: "Where does the yield (APY) come from ?",
            acccontent: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Autem dignissimos dolor praesentium repellat laudantium quasi exercitationem ullam in dolorem"
        },
        {
            acchead: "When is this vault profitable ?",
            acccontent: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Autem dignissimos dolor praesentium repellat laudantium quasi exercitationem ullam in dolorem"
        },
        {
            acchead: "When does this vault perform sub-optimally ?",
            acccontent: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Autem dignissimos dolor praesentium repellat laudantium quasi exercitationem ullam in dolorem"
        },
        {
            acchead: "Is there an insurance fund and what happens when there is bad debt ?",
            acccontent: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Autem dignissimos dolor praesentium repellat laudantium quasi exercitationem ullam in dolorem"
        },
    ]);


    const [debt] = useState([
        {
            valone: "Daily new debt limit",
            valtwo: "25,000,000 USDC"
        },
        {
            valone: "New debt this epoch",
            valtwo: "138,311.712148 USDC"
        },
        {
            valone: "Epoch resets in",
            valtwo: "715 mins"
        },
    ]);

    const [collateral] = useState([
        {
            valone: "Daily withdrawal cap",
            valtwo: "20,000,000 USDC"
        },
        {
            valone: "withdrawn this epoch",
            valtwo: "-8,275,996.327483 USDC"
        },
        {
            valone: "Epoch resets in",
            valtwo: "566 mins"
        },
    ]);

    // const updateOptionsData = {
    //     "7d": {
    //         xaxis: {
    //             min: new Date("01 Mar 2024").getTime(),
    //             max: new Date("08 Mar 2024").getTime()
    //         }
    //     },
    //     "30d": {
    //         xaxis: {
    //             min: new Date("01 Mar 2024").getTime(),
    //             max: new Date("31 Mar 2024").getTime()
    //         }
    //     },
    //     "90d": {
    //         xaxis: {
    //             min: new Date("01 Mar 2024").getTime(),
    //             max: new Date("01 Jun 2024").getTime()
    //         }
    //     },
    //     "6m": {
    //         xaxis: {
    //             min: new Date("01 Mar 2024").getTime(),
    //             max: new Date("01 Sep 2024").getTime()
    //         }
    //     },
    //     all: {
    //         xaxis: {
    //             min: undefined,
    //             max: undefined
    //         }
    //     }
    // }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }, []);

    const [tabbtn] = useState([
        {
            val: "Market Overview"
        },
        {
            val: "Info & Risk"
        },
    ]);



    return (
        <>
            <Header />

            <article className="innerpages">
                <section className="supplyborrow">
                    <Container>
                        <div className="d-block d-lg-flex flex justify-content-between gap-2">
                            <div className="flex gap-2 mb-4 mb-lg-0">
                                <Link to="/" className="btn sitebtn backbtn bgbtn"><GoArrowLeft /></Link>
                                <img src={Images.usdc} alt="" className="img-fluid topheadimg" />
                                <p className="tophead">USDC Reserve</p>
                                <Badge className="topheadbadge">Main Market</Badge>
                            </div>
                            <div className="boxbg rightboxbg">
                                <div className="flex gridb">
                                    {price.map((pr) => (
                                        <>
                                            <p className="content t-light-grey">
                                                <span className="t-light-grey text-capitalize">
                                                    {pr.priceone}
                                                </span>
                                                {pr.pricetwo &&
                                                    <span className={`text-white ms-2 ${pr.priceone.includes("supply") ? "text-color-light" : pr.priceone.includes("borrow") && "t-red"}`}>{pr.pricetwo}</span>
                                                }
                                            </p>
                                            {pr.pricetwo && <span className="t-light-grey mx-2">|</span>}
                                        </>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="flex align-items-stretch justify-content-between mt-4 mt-sm-5 sm-flex">
                            <div className="tabs boxbg p-0">
                                {tabbtn.map((tb, i) => (
                                    <button type="button" className={`btn tabbtn ${tab === (i + 1) && "bgbtn"}`} onClick={() => setTab((i + 1))}>{tb.val}</button>
                                ))}
                                {/* <button type="button" className={`btn tabbtn ${tab === 1 && "bgbtn"}`} onClick={() => setTab(1)}>Market Overview</button>
                                <button type="button" className={`btn tabbtn ${tab === 2 && "bgbtn"}`} onClick={() => setTab(2)}>Info & Risk</button> */}
                            </div>
                            <button type="button" className="btn boxbg text-white text-capitalize" onClick={() => setShow(true)}>
                                <img src={Images.quiz} alt="" className="img-fluid" width="18" />
                                <span className="ms-2">quiz</span>
                            </button>
                        </div>
                        {tab === 1 &&
                            <Row className="mt-4 f-column">
                                <Col xs={12} sm={12} md={12} lg={8}>
                                    <div className="innerbg mb-3">
                                        <div className={`innerbgdiv ${coindet.length > 3 ? "gridb" : ""}`}>
                                            {coindet.map((ct) => (
                                                <div className="innerbox">
                                                    <span className="mb-2">{ct.coinhead}</span>
                                                    <span className="t-light-grey">{ct.coinvalue}</span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="innerbg mb-3 chartdrop chartcheckbox">
                                        <div id="chartone">
                                            <ReactApexChart options={chartoption.options} series={chartoption.series} type="line" height="500" />
                                            {/* <Chart options={chartoption.options} series={chartoption.options.series} type="line" /> */}
                                            <Dropdown>
                                                <Dropdown.Toggle className="fnt-14">
                                                    <span className="t-light-grey">Period:</span> {chartdrop}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="mt-2">
                                                    <Dropdown.Item as="button" onClick={() => updateData("7D")}>
                                                        7 days
                                                    </Dropdown.Item>
                                                    <Dropdown.Item as="button" onClick={() => updateData("30D")}>
                                                        30 days
                                                    </Dropdown.Item>
                                                    <Dropdown.Item as="button" onClick={() => updateData("90D")}>
                                                        90 days
                                                    </Dropdown.Item>
                                                    <Dropdown.Item as="button" onClick={() => updateData("6M")}>
                                                        6 Months
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            <Form className="flex gap-4 chartcheck">
                                                {chartcheck.map((ck, i) => (
                                                    <Form.Check type="checkbox" label={ck.value} className={`chartcheck${i}`} onClick={() => updateData(ck.valuedate)}></Form.Check>
                                                ))}
                                            </Form>
                                        </div>
                                    </div>
                                    <div className="innerbg mb-3 p-3">
                                        <h6 className="innerbg_head">Supply Info</h6>
                                        <div className={`innerbgdiv align-items-stretch gap-md-4 ${supply.length > 3 ? "gridb" : ""}`}>
                                            {supply.map((sy) => (
                                                <div className="innerbox">
                                                    <span className="mb-2 t-light-grey text-capitalize">{sy.valone}</span>
                                                    <span className={`mb-2 text-white fnt-16 ${sy.valone.toLowerCase().includes("apy") && "text-color-light"}`}>{sy.valtwo}</span>
                                                    <span className="t-light-grey">{sy.valthree}</span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="innerbg mb-3 p-3">
                                        <h6 className="innerbg_head">Borrow Info</h6>
                                        <div className={`innerbgdiv align-items-stretch gap-md-4 ${borrow.length > 3 ? "gridb" : ""}`}>
                                            {borrow.map((bw) => (
                                                <div className="innerbox">
                                                    <span className="mb-2 t-light-grey text-capitalize">{bw.valone}</span>
                                                    <span className={`mb-2 text-white fnt-16 ${bw.valone.toLowerCase().includes("apy") && "t-red"}`}>{bw.valtwo}</span>
                                                    <span className="t-light-grey">{bw.valthree}</span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="innerbg mb-3 p-3">
                                        <h6 className="innerbg_head">Liquidation Info</h6>
                                        <div className={`innerbgdiv align-items-stretch gap-md-4 ${liquidation.length > 3 ? "gridb" : ""}`}>
                                            {liquidation.map((ln) => (
                                                <div className="innerbox">
                                                    <span className={`mb-2 t-light-grey text-capitalize ${ln.valone.toLowerCase().includes("penalty") && "bt-line"}`}>{ln.valone}</span>
                                                    <span className="fnt-16 text-white">{ln.valtwo}</span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="innerbg chartonebox">
                                        <div id="charttwo">
                                            <ReactApexChart options={chartoptiontwo.options} series={chartoptiontwo.series} type="line" height="500" />
                                        </div>
                                        <div className="flex gap-3 flex-column flex-sm-row chartcheck">
                                            <div>
                                                <img src={Images.borrowicon} alt="" className="img-fluid" width="20" />
                                                <span className="text-white fnt-14 ms-2">Borrow APY</span>
                                            </div>
                                            <div>
                                                <img src={Images.supplyicon} alt="" className="img-fluid" width="20" />
                                                <span className="text-white fnt-14 ms-2">Supply APY</span>
                                            </div>
                                            <div>
                                                <span className="text-white fnt-14">
                                                    &#8212; Current Utilization: 58.31%
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={4} className="mb-4 mb-lg-0">
                                    <Depositbox supplydet={supplydet} rangename="supplyborrow" />
                                    {/* <div className="innerbg p-3">
                                        <div className="usemarketrightbox">
                                            <div className="flex justify-content-between">
                                                <div>
                                                    <h6 className="rightbox_head">Use Market</h6>
                                                </div>
                                                <div>
                                                    <Dropdown>
                                                        <Dropdown.Toggle className="text-capitalize fnt-15">
                                                            {drop}
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu className="mt-2">
                                                            <Dropdown.Item as="button" className="text-capitalize" onClick={() => setDrop("supply")}>
                                                                <span className={`${drop === "supply" && "text-color"}`}>supply</span>
                                                            </Dropdown.Item>
                                                            <Dropdown.Item as="button" className="text-capitalize" onClick={() => setDrop("withdraw")}>
                                                                <span className={`${drop === "withdraw" && "text-color"}`}>withdraw</span>
                                                            </Dropdown.Item>
                                                            <Dropdown.Item as="button" className="text-capitalize" onClick={() => setDrop("borrow")}>
                                                                <span className={`${drop === "borrow" && "text-color"}`}>borrow</span>
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                            <div className="boxbg my-4">
                                                <div className="flex justify-content-between">
                                                    <span className="text-white">Your {drop}</span>
                                                    <span className="t-light-grey fnt-14 smbg">~$0.00</span>
                                                </div>
                                                <div className="flex justify-content-between my-4">
                                                    <div>
                                                        <img src={Images.usdc} alt="" className="img-fluid" width="30" />
                                                        <span className="text-white text-uppercase ms-2">usdc</span>
                                                    </div>
                                                    <div>
                                                        <Form.Control type="text" placeholder="0" className="smbg" onChange={handlechange}></Form.Control>
                                                    </div>
                                                </div>
                                                <div className="flex justify-content-between">
                                                    <div>
                                                        <span className="t-light-grey text-capitalize">
                                                            Available: {drop === "supply" ? 49.943415 : drop === "withdraw" ? 0 : drop === "borrow" && 0}
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span className="text-white smbg text-capitalize me-2">half</span>
                                                        <span className="text-white smbg text-capitalize">max</span>
                                                    </div>
                                                </div>
                                            </div>
                                            {showbtn ?
                                                <button type="button" className="btn sitebtn bgbtn w-100 mb-3" onClick={() => setShowbtnone(true)}>Deposit</button> :
                                                <button type="button" className="btn sitebtn bgbtn w-100 mb-3" onClick={() => setShowmodal(true)}>Connect Wallet</button>

                                            }

                                            {drop === "supply" &&
                                                <div className="boxbg">
                                                    <div className="flex gap-3">
                                                        <img src={Images.alert} alt="" className="img-fluid" width="30" />
                                                        <p className="content fnt-14 lh-24">
                                                            USDC Loan-to-value ratio is 0%. This means USDC cannot be used as collateral.
                                                        </p>
                                                    </div>
                                                </div>
                                            }
                                            <div className="flex justify-content-between mt-3">
                                                <span className="t-light-grey fnt-15">Transaction Settings</span>
                                                <button type="button" className="btn p-0" onClick={() => setShowtwo(true)}>
                                                    <span className="text-white text-capitalize fnt-15 me-2">medium</span>
                                                    <img src={Images.settings} alt="" className="img-fluid" width="24" />
                                                </button>
                                            </div>
                                            {supplyshow &&
                                                <div className="supplydet mt-2">
                                                    {supplydet.map((st) => (
                                                        <div className="flex justify-content-between">
                                                            <span className="t-light-grey fnt-14">{st.valone}</span>
                                                            <span className="text-white fnt-14">
                                                                {st.valtwo}
                                                                {st.valone.toLowerCase().includes("deposit") &&
                                                                    <span className="t-light-grey ms-1 fnt-13">
                                                                        (${st.valtwo})
                                                                    </span>
                                                                }
                                                            </span>
                                                        </div>
                                                    ))}
                                                </div>
                                            }
                                        </div>
                                    </div> */}
                                </Col>
                            </Row>
                        }
                        {tab === 2 &&
                            <Row className="mt-4 f-column">
                                <Col xs={12} md={12} lg={8}>
                                    <InfoRiskAccordion data={inforiskacc} />
                                </Col>
                                <Col xs={12} md={12} lg={4} className="mb-4 mb-lg-0">
                                    <div className="innerbg p-3">
                                        <h6 class="rightbox_head">Reserve Limits</h6>
                                        <p className="mb-0 mt-3 fnt-15 text-white text-capitalize">Debt</p>
                                        <div className="supplydet mt-2 inforisk_det">
                                            {debt.map((dt) => (
                                                <div className="flex justify-content-between">
                                                    <span className="t-light-grey fnt-14">{dt.valone}</span>
                                                    <span className="text-white fnt-14">
                                                        {dt.valtwo}
                                                    </span>
                                                </div>
                                            ))}
                                        </div>
                                        <p className="mb-0 mt-4 fnt-15 text-white text-capitalize">collateral</p>
                                        <div className="supplydet mt-2 inforisk_det">
                                            {collateral.map((cl) => (
                                                <div className="flex justify-content-between">
                                                    <span className="t-light-grey fnt-14">{cl.valone}</span>
                                                    <span className="text-white fnt-14">
                                                        {cl.valtwo}
                                                    </span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        }
                    </Container>
                </section>
            </article>

            <Footer />

            <QuizModal show={show} close={() => setShow(false)} />
        </>
    )
}

export default SupplyBorrow;