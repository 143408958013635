import React from 'react';
import { Images } from '../Images';
import { CiStar } from "react-icons/ci";
import { Link, useNavigate } from 'react-router-dom';

const Table = (props) => {

    const { thead, table } = props;
    const navigate = useNavigate();
    const handleclick = () => {
        navigate("/supply-borrow");
    }

    return (
        <>
            <div className="table-responsive d-none d-lg-block">
                <table className="table">
                    <thead>
                        <tr>
                            {thead.map((th) => (
                                <th>{th} <img src={Images.theadarrow} alt="" width="10" height="10" className="img-fluid ms-1" /></th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {table.length > 0 ? table.map((td) => (
                            <tr onClick={handleclick}>
                                <td>
                                    <div className="d-flex align-items-center gap-2">
                                        <img src={td.coinimg} alt={td.coinname} className="img-fluid" width="35" height="24" />
                                        <span className="coinname">{td.coinname}</span>
                                    </div>
                                </td>
                                <td>
                                    {td.totsupply ?
                                        <>
                                            <span className="dotted-line">{td.totsupply}</span>
                                            <span>${td.totsupply}</span>
                                        </> :
                                        <span className="text-center ps-5">-</span>
                                    }
                                </td>
                                <td>
                                    {td.totborrow ?
                                        <>
                                            <span className="dotted-line">{td.totborrow}</span>
                                            <span>${td.totborrow}</span>
                                        </> :
                                        <span className="text-center ps-5">-</span>
                                    }
                                </td>
                                <td>
                                    {td.maxltv ?
                                        <span>{td.maxltv}</span> :
                                        <span className="text-center ps-5">-</span>
                                    }
                                </td>
                                <td>
                                    {td.supply ?
                                        <div className="d-flex align-items-center gap-1">
                                            <span className="text-color">{td.supply && <CiStar />}</span>
                                            <span className="text-color dotted-line dotted-line-color">{td.supply}</span>
                                            {td.supply && <button type="button" className="btn sitebtn ms-2">Supply</button>}
                                        </div> :
                                        <span className="text-center ps-5">-</span>
                                    }
                                </td>
                                <td>
                                    {td.borrow ?
                                        <div className="d-flex align-items-center justify-content-end gap-1">
                                            <span className={td.borrow && "t-red"}>{td.borrow}</span>
                                            {td.borrow && <button type="button" className="btn sitebtn ms-2">borrow</button>}
                                        </div> :
                                        <span className="text-center ps-5">-</span>
                                    }
                                </td>
                            </tr>
                        )) : 
                        <tr className="nohover">
                            <td colSpan={thead.length} className="text-center py-4">
                                <span className="text-white text-capitalize fnt-15 w-100">no data found</span>
                            </td>
                        </tr>
                        }
                        <tr className="nohover">
                            <td colSpan={thead.length} className="py-5">
                                <div className="table_centerline">
                                    <div className="d-flex align-items-center justify-content-center gap-1 table_centerflex">
                                        <span className="table_centertext">Show deprecated assets</span>
                                        <img src={Images.theadarrow} alt="" width="10" height="10" className="img-fluid ms-1" />
                                    </div>
                                </div>
                            </td>
                        </tr>
                        {table.length > 0 ? table.map((td) => (
                            <tr onClick={handleclick}>
                                <td>
                                    <div className="d-flex align-items-center gap-2">
                                        <img src={td.coinimg} alt={td.coinname} className="img-fluid" width="35" height="24" />
                                        <span className="coinname">{td.coinname}</span>
                                    </div>
                                </td>
                                <td>
                                    {td.totsupply ?
                                        <>
                                            <span className="dotted-line">{td.totsupply}</span>
                                            <span>${td.totsupply}</span>
                                        </> :
                                        <span className="text-center ps-5">-</span>
                                    }
                                </td>
                                <td>
                                    {td.totborrow ?
                                        <>
                                            <span className="dotted-line">{td.totborrow}</span>
                                            <span>${td.totborrow}</span>
                                        </> :
                                        <span className="text-center ps-5">-</span>
                                    }
                                </td>
                                <td>
                                    {td.maxltv ?
                                        <span>{td.maxltv}</span> :
                                        <span className="text-center ps-5">-</span>
                                    }
                                </td>
                                <td>
                                    {td.supply ?
                                        <div className="d-flex align-items-center gap-1">
                                            <span className="text-color">{td.supply && <CiStar />}</span>
                                            <span className="text-color dotted-line dotted-line-color">{td.supply}</span>
                                            {td.supply && <Link to="/supply-borrow" className="btn sitebtn ms-2">Supply</Link>}
                                        </div> :
                                        <span className="text-center ps-5">-</span>
                                    }
                                </td>
                                <td>
                                    {td.borrow ?
                                        <div className="d-flex align-items-center justify-content-end gap-1">
                                            <span className={td.borrow && "t-red"}>{td.borrow}</span>
                                            {td.borrow && <Link to="/supply-borrow" className="btn sitebtn ms-2">borrow</Link>}
                                        </div> :
                                        <span className="text-center ps-5">-</span>
                                    }
                                </td>
                            </tr>
                        )) : 
                        <tr className="nohover">
                            <td colSpan={thead.length} className="text-center py-4">
                                <span className="text-white text-capitalize fnt-15 w-100">no data found</span>
                            </td>
                        </tr>
                        }
                    </tbody>
                </table>
            </div>
            <div className="tablediv d-lg-none">
                <div className="tablediv_inner">
                    {table.length > 0 ? table.map((td) => (
                        <div className="tablediv_innerboxtop">
                            <div onClick={handleclick}>
                                <div className="d-flex align-items-center gap-2">
                                    <img src={td.coinimg} alt={td.coinname} className="img-fluid" width="40" height="24" />
                                    <span className="coinname text-uppercase">{td.coinname}</span>
                                </div>
                            </div>
                            <div className="tablediv_innerchildbox" onClick={handleclick}>
                                <div>
                                    {thead[1] && <div className="tablediv_head">{thead[1]}</div>}
                                    {td.totsupply ?
                                        <>
                                            <span className="dotted-line">{td.totsupply}</span>
                                            <span>${td.totsupply}</span>
                                        </> :
                                        <span className="text-center ps-5">-</span>
                                    }
                                </div>
                                <div>
                                    {thead[2] && <div className="tablediv_head">{thead[2]}</div>}
                                    {td.totborrow ?
                                        <>
                                            <span className="dotted-line">{td.totborrow}</span>
                                            <span>${td.totborrow}</span>
                                        </> :
                                        <span className="text-center ps-5">-</span>
                                    }
                                </div>
                                <div>
                                    {thead[3] && <div className="tablediv_head">{thead[3]}</div>}
                                    {td.maxltv ?
                                        <span>{td.maxltv}</span> :
                                        <span className="text-center ps-5">-</span>
                                    }
                                </div>
                                <div>
                                    {thead[4] && <div className="tablediv_head">{thead[4]}</div>}
                                    {td.supply ?
                                        <div className="d-flex align-items-center gap-1">
                                            <span className="text-color">{td.supply && <CiStar />}</span>
                                            <span className="text-color dotted-line dotted-line-color">{td.supply}</span>
                                        </div> :
                                        <span className="text-center ps-5">-</span>
                                    }
                                </div>
                                <div>
                                    {thead[5] && <div className="tablediv_head">{thead[5]}</div>}
                                    {td.borrow ?
                                        <div className="d-flex align-items-center gap-1">
                                            <span className={td.borrow && "t-red"}>{td.borrow}</span>
                                        </div> :
                                        <span className="text-center ps-5">-</span>
                                    }
                                </div>
                            </div>
                            <div>
                                <div className="d-flex align-items-center justify-content-end gap-2">
                                    <Link to="/supply-borrow" className="btn sitebtn ms-lg-2 w-100">supply</Link>
                                    <Link to="/supply-borrow" className="btn sitebtn ms-lg-2 w-100">borrow</Link>
                                </div>
                            </div>
                        </div>
                    )) :
                    <div className="tablediv_innerboxtop mb-4">
                         <span className="text-white text-capitalize fnt-15 w-100 text-center">no data found</span>
                    </div>
                    }
                    <div className="table_centerline my-5">
                        <div className="d-flex align-items-center justify-content-center gap-1 table_centerflex">
                            <span className="table_centertext">Show deprecated assets</span>
                            <img src={Images.theadarrow} alt="" width="10" height="10" className="img-fluid ms-1" />
                        </div>
                    </div>
                    {table.length > 0 ? table.map((td) => (
                        <div className="tablediv_innerboxtop">
                            <div onClick={handleclick}>
                                <div className="d-flex align-items-center gap-2">
                                    <img src={td.coinimg} alt={td.coinname} className="img-fluid" width="40" height="24" />
                                    <span className="coinname text-uppercase">{td.coinname}</span>
                                </div>
                            </div>
                            <div className="tablediv_innerchildbox" onClick={handleclick}>
                                <div>
                                    {thead[1] && <div className="tablediv_head">{thead[1]}</div>}
                                    {td.totsupply ?
                                        <>
                                            <span className="dotted-line">{td.totsupply}</span>
                                            <span>${td.totsupply}</span>
                                        </> :
                                        <span className="text-center ps-5">-</span>
                                    }
                                </div>
                                <div>
                                    {thead[2] && <div className="tablediv_head">{thead[2]}</div>}
                                    {td.totborrow ?
                                        <>
                                            <span className="dotted-line">{td.totborrow}</span>
                                            <span>${td.totborrow}</span>
                                        </> :
                                        <span className="text-center ps-5">-</span>
                                    }
                                </div>
                                <div>
                                    {thead[3] && <div className="tablediv_head">{thead[3]}</div>}
                                    {td.maxltv ?
                                        <span>{td.maxltv}</span> :
                                        <span className="text-center ps-5">-</span>
                                    }
                                </div>
                                <div>
                                    {thead[4] && <div className="tablediv_head">{thead[4]}</div>}
                                    {td.supply ?
                                        <div className="d-flex align-items-center gap-1">
                                            <span className="text-color">{td.supply && <CiStar />}</span>
                                            <span className="text-color dotted-line dotted-line-color">{td.supply}</span>
                                        </div> :
                                        <span className="text-center ps-5">-</span>
                                    }
                                </div>
                                <div>
                                    {thead[5] && <div className="tablediv_head">{thead[5]}</div>}
                                    {td.borrow ?
                                        <div className="d-flex align-items-center gap-1">
                                            <span className={td.borrow && "t-red"}>{td.borrow}</span>
                                        </div> :
                                        <span className="text-center ps-5">-</span>
                                    }
                                </div>
                            </div>
                            <div>
                                <div className="d-flex align-items-center justify-content-end gap-2">
                                    <button type="button" className="btn sitebtn ms-lg-2 w-100">supply</button>
                                    <button type="button" className="btn sitebtn ms-lg-2 w-100">borrow</button>
                                </div>
                            </div>
                        </div>
                    )) :
                    <div className="tablediv_innerboxtop mb-4">
                         <span className="text-white text-capitalize fnt-15 w-100 text-center">no data found</span>
                    </div>
                    }
                </div>
            </div>
        </>
    )
}

export default Table;