import React, { useState, useEffect } from 'react';
import Header from '../Layouts/Header';
import Footer from '../Layouts/Footer';
import { Container, Row, Col, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { GoArrowLeft } from "react-icons/go";
import { Images } from '../Components/Images';
import QuizModal from '../Components/Modals/QuizModal';
import ReactApexChart from "react-apexcharts";
import ApexCharts from 'apexcharts';
import InfoRiskAccordion from '../Components/InfoRiskAccordion';
import Depositbox from '../Components/DepositBox';

const LongShortDetail = () => {

    const [show, setShow] = useState(false);


    const [price] = useState([
        {
            priceone: "SOL Price",
            pricetwo: "$164.16",
        },
        {
            priceone: "Loop Token",
            pricetwo: "USDC",
            pricethree: Images.usdc
        },
        {
            priceone: "Liquidity Available",
            pricetwo: "$9.65M"
        },
        {
            priceone: "oracle"
        },
    ]);

    const [tab, setTab] = useState(1);

    const [longshortone] = useState([
        {
            valone: "Net Value",
            valtwo: "",
            valthree: ""
        },
        {
            valone: "PnL",
            valtwo: "",
            valthree: ""
        },
        {
            valone: "Total Exposure",
            valtwo: "",
            valthree: ""
        },
        {
            valone: "Liquidation Price",
            valtwo: "",
            valthree: ""
        },
    ]);

    const [longshorttwo] = useState([
        {
            valone: "Total Debt",
            valtwo: "",
            valthree: ""
        },
        {
            valone: "Borrow APY",
            valtwo: "",
            valthree: ""
        },
        {
            valone: "LTV",
            valtwo: "",
            valthree: ""
        },
        {
            valone: "Leverage",
            valtwo: "",
        },
    ]);

    const multiovertabhead = ["SOL Price Change", "PnL", "Your Net Value"];

    const [multiovertab] = useState([
        {
            valone: "5%",
            valtwo: "0.00%",
            valthree: "$0.00"
        },
        {
            valone: "10%",
            valtwo: "0.00%",
            valthree: "$0.00"
        },
        {
            valone: "25%",
            valtwo: "0.00%",
            valthree: "$0.00"
        },
        {
            valone: "100%",
            valtwo: "0.00%",
            valthree: "$0.00"
        },
    ]);

    const [dropcon] = useState([
        {
            dimg: Images.sol,
            dcoinname: "SOL"
        },
        {
            dimg: Images.usdc,
            dcoinname: "USDC"
        },
    ]);



    const MIN = 1.0;
    const MAX = 2.8;

    const marks = [
        {
            value: MIN,
            label: `${MIN}x`,
        },
        {
            value: MAX,
            label: `${MAX}x`,
        },
    ];

    const [val, setVal] = useState(MIN);

    const handleChange = (_, newValue) => {
        setVal(newValue);
    };

    const [longshortthree] = useState([
        {
            valone: "Net Value",
            valtwo: "",
            valthree: ""
        },
        {
            valone: "PnL",
            valtwo: "",
            valthree: ""
        },
        {
            valone: "Total Exposure",
            valtwo: "",
            valthree: ""
        },
        {
            valone: "Liquidation price",
            valtwo: "",
            valthree: ""
        },
    ]);

    const [inforiskacc] = useState([
        {
            acchead: "What does this vault do ?",
            acccontent: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Autem dignissimos dolor praesentium repellat laudantium quasi exercitationem ullam in dolorem"
        },
        {
            acchead: "Where does the yield (APY) come from ?",
            acccontent: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Autem dignissimos dolor praesentium repellat laudantium quasi exercitationem ullam in dolorem"
        },
        {
            acchead: "When is this vault profitable ?",
            acccontent: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Autem dignissimos dolor praesentium repellat laudantium quasi exercitationem ullam in dolorem"
        },
        {
            acchead: "When does this vault perform sub-optimally ?",
            acccontent: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Autem dignissimos dolor praesentium repellat laudantium quasi exercitationem ullam in dolorem"
        },
        {
            acchead: "Is there an insurance fund and what happens when there is bad debt ?",
            acccontent: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Autem dignissimos dolor praesentium repellat laudantium quasi exercitationem ullam in dolorem"
        },
    ]);

    const [resize, setResize] = useState(window.innerWidth);

    useEffect(() => {
        const handleresize = () => {
            setResize(window.innerWidth)
        }

        window.addEventListener("resize", handleresize);

        return () => {
            window.removeEventListener("resize", handleresize);
        }
    }, [resize]);

    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }, []);

    const [chartoption] = useState({
        series: [
            {
                data: [
                    [new Date('01 Mar 2024').getTime(), 140.95],
                    [new Date('02 Mar 2024').getTime(), 143.95],
                    [new Date('03 Mar 2024').getTime(), 150.34],
                    [new Date('04 Mar 2024').getTime(), 147.94],
                    [new Date('05 Mar 2024').getTime(), 144.14],
                    [new Date('06 Mar 2024').getTime(), 150.66],
                    [new Date('07 Mar 2024').getTime(), 140.95],
                    [new Date('08 Mar 2024').getTime(), 150.34],
                    [new Date('09 Mar 2024').getTime(), 155.94],
                    [new Date('10 Mar 2024').getTime(), 152.34],
                    [new Date('11 Mar 2024').getTime(), 153.94],
                ],
                color: "#BF8A22"
            },

        ],

        options: {
            chart: {
                type: 'area',
                id: "mychart",
                toolbar: {
                    show: false
                }
            },
            plotOptions: {
                line: {
                    horizontal: true
                }
            },
            dataLabels: {
                enabled: false,

            },
            // title: {
            //     text: "SOL/USDC",
            //     align: "left",
            //     style: {
            //         color: "#FFF",
            //         fontSize: "18px",
            //         fontWeight: 500
            //     },
            //     margin: 20
            // },

            xaxis: {
                showAlways: true,
                type: 'datetime',
                min: new Date('01 Mar 2024').getTime(),
                max: new Date('10 Mar 2024').getTime(),
                tickAmount: 6,
                labels: {
                    style: {
                        colors: "#fff",
                        fontFamily: '"Roboto", sans-serif',
                        fontWeight: 400,
                        fontSize: "12px"
                    },
                    format: "dd/MM",
                },
                axisBorder: {
                    show: true,
                    color: '#FFFFFF1A',
                    height: 1,
                    width: '100%'
                },
                axisTicks: {
                    show: false
                },

            },
            yaxis: [
                {
                    showAlways: true,
                    axisTicks: {
                        show: true,
                        borderType: 'solid',
                        width: 0,
                    },
                    axisBorder: {
                        show: false,
                        color: "#FFFFFF"
                    },
                    labels: {
                        style: {
                            colors: "#fff",
                            fontFamily: '"Roboto", sans-serif',
                            fontWeight: 400,
                            fontSize: "12px"
                        },
                        formatter: function (val, index) {
                            return `${val.toFixed(2)}`;
                        },
                    }
                },
            ],
            grid: {
                borderColor: '#FFFFFF1A',
                padding: {
                    left: 30,
                    right: 30
                },
            },
            tooltip: {
                x: {
                    format: 'dd MMM yyyy'
                }
            },
            stroke: {
                curve: "smooth",
                width: [1, 1]
            },
            legend: {
                show: false,
                horizontalAlign: "left",
                offsetX: 100,
                labels: {
                    colors: ["#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"]
                },

            },
        },
    });

    const [chartdrop, setChartdrop] = useState("30D");

    const updateData = (timeline) => {
        setChartdrop(timeline);
        switch (timeline) {
            case '7D':
                ApexCharts.exec('mychart', 'updateOptions', {
                    xaxis: {
                        min: new Date("01 Mar 2024").getTime(),
                        max: new Date("08 Mar 2024").getTime()
                    }
                }, false, true);
                break;
            case '30D':
                ApexCharts.exec('mychart', 'updateOptions', {
                    xaxis: {
                        min: new Date("01 Mar 2024").getTime(),
                        max: new Date("31 Mar 2024").getTime()
                    }
                }, false, true);
                break;
            case '90D':
                ApexCharts.exec('mychart', 'updateOptions', {
                    xaxis: {
                        min: new Date("01 Mar 2024").getTime(),
                        max: new Date("01 Jun 2024").getTime()
                    }
                }, false, true);
                break;
            case '6M':
                ApexCharts.exec('mychart', 'updateOptions', {
                    xaxis: {
                        min: new Date("01 Mar 2024").getTime(),
                        max: new Date("01 Sep 2024").getTime()
                    }
                }, false, true);
                break;
            default:
                ApexCharts.exec('mychart', 'updateOptions', {
                    xaxis: {
                        min: new Date("01 Mar 2024").getTime(),
                        max: new Date("31 Mar 2024").getTime()
                    }
                }, false, true);
        }
    }

    const [chartprice] = useState([
        {
            valone: "price",
            valtwo: "158.64",
        },
        {
            valone: "7D Change",
            valtwo: "+16.54%",
        }
    ]);


    const [tabbtn] = useState([
        {
            val: "Overview"
        },
        {
            val: "Live Chart"
        },
        {
            val: "Info & Risk"
        },
    ]);



    return (
        <>
            <Header />

            <article className="innerpages">
                <section className="longshortdetail">
                    <Container>
                        <div className="d-block d-lg-flex flex justify-content-between gap-2">
                            <div className="flex gap-2 mb-4 mb-lg-0">
                                <Link to="/liquidity" className="btn sitebtn backbtn bgbtn"><GoArrowLeft /></Link>
                                <img src={Images.sol} alt="" className="img-fluid topheadimg" />
                                <p className="tophead">SOL Long</p>
                            </div>
                            <div className="boxbg rightboxbg">
                                <div className="flex gridb">
                                    {price.map((pr) => (
                                        <>
                                            <p className={`content t-light-grey ${pr.priceone.toLowerCase().includes("dex") && "align-items-sm-center gap-2"}`}>
                                                <span className="t-light-grey text-capitalize">
                                                    {pr.priceone}
                                                </span>
                                                <span className={`text-white ms-sm-2 ${pr.priceone.toLowerCase().includes("liquidity") && "bt-line bt-line-color"}`}>
                                                    {pr.priceone.toLowerCase().includes("loop") &&
                                                        <>
                                                            <img src={pr.pricethree} alt="" width="20" className="img-fluid me-2" />
                                                        </>
                                                    }
                                                    {pr.pricetwo}
                                                </span>
                                            </p>
                                            {pr.pricetwo && <span className="t-light-grey mx-2">|</span>}
                                        </>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="d-md-flex align-items-sm-center justify-content-sm-between mt-4 mt-sm-5 sm-flex">
                            <div className="tabs boxbg p-0 mb-4 mb-md-0">
                                {tabbtn.map((tb, i) => (
                                    <button type="button" className={`btn tabbtn ${tab === (i + 1) && "bgbtn"}`} onClick={() => setTab((i + 1))}>{tb.val}</button>
                                ))}
                                {/* <button type="button" className={`btn tabbtn ${tab === 1 && "bgbtn"}`} onClick={() => setTab(1)}>Overview</button>
                                <button type="button" className={`btn tabbtn ${tab === 2 && "bgbtn"}`} onClick={() => setTab(2)}>Live Chart</button>
                                <button type="button" className={`btn tabbtn ${tab === 3 && "bgbtn"}`} onClick={() => setTab(3)}>Info & Risk</button> */}
                            </div>
                            <div className="text-center text-lg-start">
                                <button type="button" className="btn boxbg text-white text-capitalize" onClick={() => setShow(true)}>
                                    <img src={Images.quiz} alt="" className="img-fluid" width="18" />
                                    <span className="ms-2">quiz</span>
                                </button>
                            </div>
                        </div>
                        <Row className="mt-4 f-column">
                            <Col xs={12} sm={12} md={12} lg={8} className="multiplyoverview">
                                {tab === 1 &&
                                    <>
                                        <div className="innerbg mb-3 p-3 positionboxes">
                                            <div className="innerbgdivrow">
                                                <div className="gridbox">
                                                    {longshortone.map((sy) => (
                                                        <div>
                                                            <div className="innerbox m-auto">
                                                                <span className="mb-2 t-light-grey text-capitalize">
                                                                    {sy.valone}
                                                                </span>
                                                                <span className="mt-2 text-white fnt-16">
                                                                    {sy.valtwo === "" ? "-" : sy.valtwo}
                                                                </span>
                                                                <span className="mt-2 text-white fnt-16">
                                                                    {sy.valthree === "" ? "-" : sy.valthree}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="innerbg mb-3 p-3 analyticsone">
                                            <div className="gridbox">
                                                {longshorttwo.map((sy) => (
                                                    <div className="innerbox">
                                                        <span className={`mb-2 t-light-grey text-capitalize ${sy.valone.toLowerCase().includes("ltv") && "bt-line bt-line-color"}`}>
                                                            {sy.valone}
                                                        </span>
                                                        <span className={`${sy.valone.toLowerCase().includes("ltv") && "text-color"} text-white fnt-16`}>
                                                            {sy.valtwo ? sy.valtwo : "-"}
                                                        </span>
                                                        <span className="t-light-grey">
                                                            {sy.valthree === "" && "-"}
                                                        </span>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="innerbg mb-3 p-3 topbox">
                                            <h5 class="h5 conhead text-white fw-400">Your Simulated Performance</h5>
                                            <hr className="bt-line" />
                                            <div className="multiovertab">
                                                <div className="multiovertabhead gridbox mb-3">
                                                    {multiovertabhead.map((mth) => (
                                                        <span className={`fnt-14 ${mth.toLowerCase().includes("estimated") && "bt-line"}`}>{mth}</span>
                                                    ))}
                                                </div>
                                                <div className="multiovertabbody">
                                                    {multiovertab.map((mb) => (
                                                        <div className="gridbox">
                                                            {resize < 576 && <span className="t-light-grey fnt-14">{multiovertabhead[0]}</span>}
                                                            <span className="text-white fnt-14">{mb.valone}</span>
                                                            {resize < 576 && <span className="t-light-grey fnt-14">{multiovertabhead[1]}</span>}
                                                            <span className="text-white fnt-14">{mb.valtwo}</span>
                                                            {resize < 576 && <span className="t-light-grey fnt-14">{multiovertabhead[2]}</span>}
                                                            <span className="text-white fnt-14">{mb.valthree}</span>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                                {tab === 2 &&
                                    <>
                                        <div className="innerbg mb-3 p-3 chartdrop">
                                            <div className="d-sm-flex justify-content-sm-between chartcontdrop px-2 px-sm-3 mb-3">
                                                <div className="flex gap-4 mb-4 mb-sm-0">
                                                    <div className="me-4">
                                                        <h6 className="innerbg_head mb-0">SOL/USDC</h6>
                                                    </div>
                                                    {chartprice.map((ce) => (
                                                        <div>
                                                            <span className="t-light-grey fnt-14 text-capitalize">{ce.valone}</span>
                                                            <span className={`text-white fnt-14 d-block ${ce.valone.toLowerCase().includes("change") && "text-color"}`}>{ce.valtwo}</span>
                                                        </div>
                                                    ))}
                                                </div>
                                                <Dropdown className="text-center text-sm-start">
                                                    <Dropdown.Toggle className="fnt-14">
                                                        <span className="t-light-grey">Period:</span> {chartdrop}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="mt-2">
                                                        <Dropdown.Item as="button" onClick={() => updateData("7D")}>
                                                            7 days
                                                        </Dropdown.Item>
                                                        <Dropdown.Item as="button" onClick={() => updateData("30D")}>
                                                            30 days
                                                        </Dropdown.Item>
                                                        <Dropdown.Item as="button" onClick={() => updateData("90D")}>
                                                            90 days
                                                        </Dropdown.Item>
                                                        <Dropdown.Item as="button" onClick={() => updateData("6M")}>
                                                            6 Months
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                            <div id="chartone">
                                                <ReactApexChart options={chartoption.options} series={chartoption.series} type="area" height="400" />
                                            </div>
                                        </div>
                                        <div className="innerbg mb-3 p-3 analyticsone">
                                            <div className="gridbox">
                                                {longshortthree.map((sy) => (
                                                    <div className="innerbox">
                                                        <span className="mb-2 t-light-grey text-capitalize">
                                                            {sy.valone}
                                                        </span>
                                                        <span className={`${sy.valone.toLowerCase().includes("ltv") && "text-color"} text-white fnt-16`}>
                                                            {sy.valtwo ? sy.valtwo : "-"}
                                                        </span>
                                                        <span className="t-light-grey">
                                                            {sy.valthree === "" && "-"}
                                                        </span>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </>
                                }
                                {tab === 3 &&
                                    <>
                                        <InfoRiskAccordion data={inforiskacc} />
                                    </>
                                }
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={4} className="mb-4 mb-lg-0">
                                <Depositbox dropcon={dropcon} marks={marks} min={MIN} max={MAX} val={val} handleChange={handleChange} mediumvalue="0.50%" rangename="Leverage" />
                            </Col>
                        </Row>
                    </Container>
                </section>
            </article>

            <Footer />

            <QuizModal show={show} close={() => setShow(false)} />

        </>
    )
}

export default LongShortDetail;