import React, { useState, useEffect } from 'react';
import Header from '../Layouts/Header';
import Footer from '../Layouts/Footer';
import { Container, InputGroup, Form, Row, Col } from 'react-bootstrap';
import { Images } from '../Components/Images';
import BannerDropdown from '../Components/BannerDropdown';
import CoinBox from '../Components/CoinBox';
import LongShortTable from '../Components/Tables/LongShortTable';

const LongShort = () => {

    const thead = ["Long Token", "Max Leverage", "Borrow Rate (APY)", "Available Liquidity", "Leverage with"];
    const [table] = useState([
        {
            coinimg: Images.sol,
            coinname: "SOL",
            tdone: "2.9x",
            tdtwo: "14.51%",
            tdthree: "$10.01M",
            tdfour: "USDC",
        },
        {
            coinimg: Images.sol,
            coinname: "SOL",
            tdone: "2.9x",
            tdtwo: "14.51%",
            tdthree: "$10.01M",
            tdfour: "USDC",
        },
        {
            coinimg: Images.jitosol,
            coinname: "JitoSOL",
            tdone: "1.8x",
            tdtwo: "14.51%",
            tdthree: "$10.01M",
            tdfour: "USDC",
        },
    ]);

    const [coinbox] = useState([
        {
            coinimg: Images.coinone,
            coinname: "SOL Bear 2.9x",
            badgeone: "Low-Cost Short",
            badgetwo: "1-2.9x Range",
            badgethree: "14.51%",
        },
        {
            coinimg: Images.coinone,
            coinname: "JitoSOL Bull 1.8x",
            badgeone: "Yield while Long",
            badgetwo: "Up to 1.8x Long",
            badgethree: "14.51%",
        },
        {
            coinimg: Images.coinone,
            coinname: "USDT Bear 6.7x",
            badgeone: "Stablecoin Leverage",
            badgetwo: "1-6.7x Range",
            badgethree: "14.51%",
        },
    ]);

    useEffect(() => {
        window.scroll({
            left: 0,
            top: 0,
            behavior: "smooth"
        });
    }, []);

    const [changeinput, setChangeinput] = useState("");

    const handlechange = (e) => {
        let lowercase = e.target.value.toLowerCase();
        setChangeinput(lowercase);
    }

    const filtered = table.filter((tb) => {
        if(changeinput === "") {
            return tb;
        } else {
            return tb.coinname.toLowerCase().includes(changeinput);
        }
    });

    return (
        <>
            <Header />
            <article className="longshort">
                <section className="bannersection innerbanner homebanner">
                    <Container>
                        <BannerDropdown />
                        <p className="content bannercontent text-center">
                        Easily increase your directional exposure with simple, one-click leverage. <span className="text-color">How it works</span>
                        </p>
                        <Row className="mt-5 d-lg-none">
                            <Col xs={12} md={6} className="mb-5">
                                <div>
                                    <img src={Images.longshortone} alt="" className="img-fluid bannerimgs" />
                                    <p className="content bannerimgscontent">
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos temporibus, odio
                                    </p>
                                </div>
                            </Col>
                            <Col xs={12} md={6} className="mb-5">
                                <div>
                                    <img src={Images.longshorttwo} alt="" className="img-fluid bannerimgs" />
                                </div>
                                <p className="content bannerimgscontent">
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos temporibus, odio
                                </p>
                            </Col>
                            <Col xs={12} md={6} className="mb-5 mb-md-0">
                                <div>
                                    <img src={Images.longshortthree} alt="" className="img-fluid bannerimgs" />
                                </div>
                                <p className="content bannerimgscontent">
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos temporibus, odio
                                </p>
                            </Col>
                            <Col xs={12} md={6}>
                                <div className="flexbox_div mh-auto">
                                    <img src={Images.longshortfour} alt="" className="img-fluid bannerimgs" width="100" />
                                <p className="content bannerimgscontent">
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos temporibus, odio
                                </p>
                                </div>
                            </Col>
                        </Row>
                        <div className="bannerimgtop d-none d-lg-block">
                            <img src={Images.longshortbanner} alt="" className="img-fluid banner_image" />
                            <p className="content bannerimgscontent bone">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos temporibus, odio
                            </p>
                            <p className="content bannerimgscontent btwo">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos temporibus, odio
                            </p>
                            <p className="content bannerimgscontent bthree">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos temporibus, odio
                            </p>
                            <p className="content bannerimgscontent bfour">
                                Lorem ipsum dolor sit amet consectetur
                            </p>
                        </div>
                    </Container>
                    <div className="bannerbottom"></div>
                </section>
                <section className="coinsbox pb-3">
                    <Container>
                        <CoinBox coinbox={coinbox} type="longshort" />
                    </Container>
                </section>
                <section className="tablesection">
                    <Container>
                        <Form className="mb-5">
                            <InputGroup>
                                <InputGroup.Text>
                                    <img src={Images.search} alt="Search" className="img-fluid" width="20" height="20" />
                                </InputGroup.Text>
                                <Form.Control type="text" placeholder="Search..." onChange={handlechange}></Form.Control>
                            </InputGroup>
                        </Form>
                        <LongShortTable thead={thead} table={filtered} />
                        <div className="table_underflex d-flex align-items-center justify-content-center gap-2 gap-sm-3 my-4">
                            <div>
                                <p className="content">Total Supplied <strong>$1.47B</strong></p>
                            </div>
                            <div>
                                <span className="t-grey">|</span>
                            </div>
                            <div>
                                <p className="content">Total Borrowed <strong>$527.66M</strong></p>
                            </div>
                            <div>
                                <span className="t-grey">|</span>
                            </div>
                            <div>
                                <p className="content">Borrow / Lend TVL <strong>$938.12M</strong></p>
                            </div>
                        </div>
                    </Container>
                </section>
            </article>

            <Footer />
        </>
    )
}

export default LongShort;