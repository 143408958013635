import React, { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { IoMdClose } from "react-icons/io";

const SettingsModal = ({ show, close }) => {

    const [tab, setTab] = useState(1);

    return (
        <>
            <Modal show={show} onHide={close} centered className="settings">
                <Modal.Body>
                    <button type="button" className="btn modal_closebtn" onClick={close}>
                        <IoMdClose />
                    </button>
                    <h6 className="modal_head mb-4">Settings</h6>
                    <span className="text-white fnt-15">Priority fee</span>
                    <div className="tabs boxbg p-0 my-3">
                        <button type="button" className={`btn tabbtn ${tab === 1 && "bgbtn"}`} onClick={() => setTab(1)}>Minimum</button>
                        <button type="button" className={`btn tabbtn ${tab === 2 && "bgbtn"}`} onClick={() => setTab(2)}>Medium</button>
                        <button type="button" className={`btn tabbtn ${tab === 3 && "bgbtn"}`} onClick={() => setTab(3)}>Turbo</button>
                    </div>
                    <div className="tabs_body">
                        <div className="boxbg">
                            <div className="flex">
                                <span className="fnt-14 t-light-grey">Custom max priority fee (SOL)</span>
                                <Form.Control type="text" value={tab === 1 ? "0.00000001" : tab === 2 ? " 0.000107410" : tab === 3 && " 0.005"}></Form.Control>
                            </div>
                        </div>
                        <p className="t-light-grey fnt-14 my-3">
                            The priority fee is paid to the solana network. This additional fee helps boost how a transaction is prioritized against others, resulting in faster transaction execution times.
                        </p>
                        <div className="flex gap-3">
                            <div>
                                <Form.Check type="checkbox" className="modalcheck"></Form.Check>
                            </div>
                            <div>
                                <span className="text-white d-block fnt-15">Versioned Transaction</span>
                                <span className="text-white fnt-14">(Please, uncheck if using ledger or walletConnect)</span>
                            </div>
                        </div>
                        <div className="flex mt-3">
                            <button type="button" className="btn sitebtn bgbtn w-100 me-3">Save</button>
                            <button type="button" className="btn sitebtn w-100" onClick={close}>Cancel</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default SettingsModal;