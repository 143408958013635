import React, { useState } from 'react';
import { Dropdown, Form } from 'react-bootstrap';
import ConnectWalletModal from './Modals/ConnectWalletModal';
import Settings from './Modals/SettingsModal';
import Slider from '@mui/material/Slider';
import { Images } from '../Components/Images';
import DepositModal from './Modals/DepositModal';

const Depositbox = ({ dropcon, marks, min, max, val, handleChange, mediumvalue, rangename, tab, supplydet }) => {

    const [showmodal, setShowmodal] = useState(false);
    const [showtwo, setShowtwo] = useState(false);

    const [drop, setDrop] = useState({
        dimg: dropcon && dropcon[0].dimg,
        dcoinname: dropcon && dropcon[0].dcoinname
    });

    const [dropone, setDropone] = useState("supply");
    const [supplyshow, setSupplyshow] = useState(false);
    const [showbtn, setShowbtn] = useState(false);

    const handlechange = (e) => {
        if (dropone === "supply" && e.target.value.length > 0) {
            setSupplyshow(true);
            setShowbtn(true);
        } else {
            setSupplyshow(false);
            setShowbtn(false);
        }
    }

    const [showbtnone, setShowbtnone] = useState(false);


    return (
        <>
            <div className="innerbg p-3">
                <div className="liquidityrightbox usemarketrightbox">
                    {rangename === "supplyborrow" &&
                        <div className="flex justify-content-between mb-4">
                            <div>
                                <h6 className="rightbox_head">Use Market</h6>
                            </div>
                            <div>
                                <Dropdown>
                                    <Dropdown.Toggle className="text-capitalize fnt-15">
                                        {dropone}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="mt-2">
                                        <Dropdown.Item as="button" className="text-capitalize" onClick={() => setDropone("supply")}>
                                            <span className={`${dropone === "supply" && "text-color"}`}>supply</span>
                                        </Dropdown.Item>
                                        <Dropdown.Item as="button" className="text-capitalize" onClick={() => setDropone("withdraw")}>
                                            <span className={`${dropone === "withdraw" && "text-color"}`}>withdraw</span>
                                        </Dropdown.Item>
                                        <Dropdown.Item as="button" className="text-capitalize" onClick={() => setDropone("borrow")}>
                                            <span className={`${dropone === "borrow" && "text-color"}`}>borrow</span>
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    }
                    <div className="boxbg">
                        <div className="flex justify-content-between">
                            {rangename === "supplyborrow" ?
                                <span className="text-white">Your {dropone}</span>
                                :
                                <span className="text-white">
                                    Your
                                    {(rangename.toLowerCase() === "multiply" || rangename.toLowerCase() === "leverage") ? " deposit" : " supply"}
                                </span>}
                            <span className="t-light-grey fnt-14 smbg">~$0.00</span>
                        </div>
                        {rangename === "supplyborrow" ?
                            <div className="flex justify-content-between my-4">
                                <div>
                                    <img src={Images.usdc} alt="" className="img-fluid" width="30" />
                                    <span className="text-white text-uppercase ms-2">usdc</span>
                                </div>
                                <div>
                                    <Form.Control type="text" placeholder="0" className="smbg" onChange={handlechange}></Form.Control>
                                </div>
                            </div> :
                            <div className="flex justify-content-between my-4">
                                <div>
                                    <Dropdown>
                                        <Dropdown.Toggle className="text-capitalize fnt-15">
                                            <img src={drop.dimg} alt="" className="img-fluid" />
                                            <span>{drop.dcoinname}</span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="mt-2">
                                            {dropcon.map((dp) => (
                                                <Dropdown.Item as="button" className="text-capitalize" onClick={() => setDrop(dp)}>
                                                    <img src={dp.dimg} alt="" className="img-fluid" />
                                                    <span>{dp.dcoinname}</span>
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                <div>
                                    <Form.Control type="text" placeholder="0" className="smbg"></Form.Control>
                                </div>
                            </div>
                        }

                        <div className="flex justify-content-between">
                            <div>
                                <span className="t-light-grey text-capitalize">
                                    Available: {rangename === "supplyborrow" ?
                                        <>
                                            {dropone === "supply" ? 49.943415 : dropone === "withdraw" ? 0 : dropone === "borrow" && 0}
                                        </> :
                                        <>0 {rangename.toLowerCase() === "liquidity" && drop.dcoinname}</>
                                    }
                                </span>
                            </div>
                            <div>
                                <span className="text-white smbg text-capitalize me-2">half</span>
                                <span className="text-white smbg text-capitalize">max</span>
                            </div>
                        </div>
                    </div>
                    <div className="my-3">
                        {(rangename.toLowerCase() === "multiply" || rangename.toLowerCase() === "leverage") &&
                            <>
                                <span className="text-white fnt-14">{rangename}</span>
                                <Slider defaultValue={1.0} step={1.0} valueLabelDisplay="auto" marks={marks} value={val} min={min} max={max} onChange={handleChange} />
                            </> 
                        }
                        {rangename === "liquidity" &&
                            <p className="content text-white fnt-15 text-center">Please, connect your wallet to see the balance</p>
                        }
                    </div>
                    {showbtn ?
                        <button type="button" className="btn sitebtn bgbtn w-100" onClick={() => setShowbtnone(true)}>Deposit</button> :
                        <button type="button" className="btn sitebtn bgbtn w-100" onClick={() => setShowmodal(true)}>Connect Wallet</button>
                    }
                    {rangename === "supplyborrow" &&
                        <>
                            {dropone === "supply" &&
                                <div className="boxbg mt-3">
                                    <div className="flex gap-3">
                                        <img src={Images.alert} alt="" className="img-fluid" width="30" />
                                        <p className="content fnt-14 lh-24">
                                            USDC Loan-to-value ratio is 0%. This means USDC cannot be used as collateral.
                                        </p>
                                    </div>
                                </div>
                            }
                        </>
                    }
                    {rangename !== "liquidity" &&
                        <div className="flex justify-content-between mt-3">
                            <span className="t-light-grey fnt-15">Transaction Settings</span>
                            <button type="button" className="btn p-0" onClick={() => setShowtwo(true)}>
                                <span className="text-white text-capitalize fnt-15 me-2">
                                    {rangename &&
                                        <>
                                            {rangename.toLowerCase().includes("leverage") && <>{mediumvalue}, </>}
                                            {
                                                (rangename.toLowerCase().includes("multiply") && tab === 2) &&
                                                <>{mediumvalue}, </>
                                            }
                                        </>
                                    }
                                    Medium
                                </span>
                                <img src={Images.settings} alt="" className="img-fluid" width="24" />
                            </button>
                        </div>
                    }
                    {supplyshow &&
                        <div className="supplydet mt-2">
                            {supplydet.map((st) => (
                                <div className="flex justify-content-between">
                                    <span className="t-light-grey fnt-14">{st.valone}</span>
                                    <span className="text-white fnt-14">
                                        {st.valtwo}
                                        {st.valone.toLowerCase().includes("deposit") &&
                                            <span className="t-light-grey ms-1 fnt-13">
                                                (${st.valtwo})
                                            </span>
                                        }
                                    </span>
                                </div>
                            ))}
                        </div>
                    }
                </div>
            </div>


            <ConnectWalletModal show={showmodal} close={() => setShowmodal(false)} />
            <Settings show={showtwo} close={() => setShowtwo(false)} />
            <DepositModal show={showbtnone} close={() => setShowbtnone(false)} />
        </>
    )
}

export default Depositbox;